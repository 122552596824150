import { Checkbox, CheckboxGroupProps, Grid, Group, } from "@mantine/core";
import { Ref, forwardRef, useContext, } from "react";
import { InstantSearch, useRefinementList, } from "react-instantsearch";
import TypesenseInstantsearchAdapter from "typesense-instantsearch-adapter";
import { GlobalDataContext, MyUserDataContext } from "../contexts";
import { createRenderingInfoPrinter } from "../utils";
import { GlobalDataType, } from "../types";


const debug = createRenderingInfoPrinter("SearchBoxComponent");

interface CustomerTagSelectComponentProps {
    defaultValue?: string[];
    textProps?: Partial<CheckboxGroupProps>;
    onChange: (item: string[]) => void,
    tagsList: string[],
    fundMap?: GlobalDataType["fundsMap"],
}

export interface AutocompleteComponentHandle {

}

const SearchBoxComponent = forwardRef<AutocompleteComponentHandle, CustomerTagSelectComponentProps>(
    ({ textProps, defaultValue, tagsList, onChange, fundMap, ...props }: CustomerTagSelectComponentProps, ref: Ref<AutocompleteComponentHandle>) => {

        debug();

        const {
            items,
            refine,

        } = useRefinementList({ ...props, attribute: "tags_object_arr.code", limit: 999 });



        return <Grid >
            <Checkbox.Group
                {...textProps}
                style={{ width: "100%" }}
                defaultValue={defaultValue}
                onChange={
                    (items) => {
                        if (onChange) {
                            onChange(items)
                        }
                    }
                }
                value={tagsList}
            >
                <Group mt="xs">

                    {items.map((item, idx) => {

                        return <Checkbox
                            key={idx}
                            type="checkbox"
                            color="green"
                            checked={item.isRefined}
                            onChange={() => {
                                refine(item.value)
                            }}
                            value={item.value}
                            label={
                                <>
                                    <span>({item.count})</span>  {fundMap && fundMap[item.value] ? (fundMap[item.value]?.name_en) || fundMap[item.value]?.name || fundMap[item.value].code : item.label}
                                </>
                            }
                        />
                    }
                    )}
                </Group>
            </Checkbox.Group>


        </Grid >

    }
);

export const CustomerTagSelectComponent = forwardRef<AutocompleteComponentHandle, CustomerTagSelectComponentProps>(
    ({ textProps, defaultValue, onChange, tagsList }: CustomerTagSelectComponentProps, ref: Ref<AutocompleteComponentHandle>) => {
        const userData = useContext(MyUserDataContext);
        const dataContext = useContext(GlobalDataContext);
        const fundMap = dataContext.fundsMap;

        const typesenseInstantsearchAdapter = new TypesenseInstantsearchAdapter({
            server: {
                apiKey: userData.customerCollectionSearchKey!, // Be sure to use a Search API Key
                nodes: [
                    {
                        host: process.env.REACT_APP_TYPESENSE_SEARCH_HOST!,
                        port: 443,
                        protocol: 'https',

                    },

                ],


            },
            additionalSearchParameters: {
                query_by: "tags_object_arr",
                facet_by: "tags_object_arr.code",
                max_facet_values: 999,

            }
        });

        const typesenseSearchClient = typesenseInstantsearchAdapter.searchClient;

        return (
            <InstantSearch indexName={process.env.REACT_APP_TYPESENSE_CUSTOMERS_COLLECTION as string}

                searchClient={typesenseSearchClient}>
                <SearchBoxComponent tagsList={tagsList} textProps={textProps} defaultValue={defaultValue} onChange={onChange} fundMap={fundMap} />
            </InstantSearch>
        );
    }
);
