import { Checkbox, Grid, Loader, TextInput, Text, Table, Divider, Space } from '@mantine/core'
import { useEffect, useRef, useState } from 'react'
import { DateTimePicker } from '@mantine/dates';


import { collectionGroup, onSnapshot, query, Timestamp, where } from 'firebase/firestore';

import { httpsCallable } from 'firebase/functions';
import { CustodianAccount, CustodianAccountWithBalance, CustomAutocomplete, Customer, FirebaseTransaction, Investment, Portfolio } from '../../types';
import { AutocompleteComponent } from '../../components/autocomplete_component';
import { UseFormReturnType } from '@mantine/form';
import { CustomerSelectComponent } from '../../components/customer_select_component';
import { useFirestore, useFunctions } from '../../helpers/firebaseContext';

/*
export interface TrialBalanceRow {
    CAT: string,
    CLOSINGBAL: number,
    CREDITS: number,
    DEBITS: number,
    DETAIL: string,
    OPENINGBAL: number,
    SECT: string,
    SUBCAT: string,
    TEMPCLOSINGBAL: number,
    TEMPCREDITS: number,
    TEMPDEBITS: number,

}
*/

interface TransactionsPanelProps {
    form: UseFormReturnType<FirebaseTransaction>
    custodianAccountsParent: CustodianAccount[]
    disabled: boolean
    disableFetch: boolean
    transactionType?: string
}



const TransactionsPanel = ({ form, custodianAccountsParent, disabled, disableFetch }: TransactionsPanelProps) => {
    // const [formData, seFormData] = useState<any>({ ...form.values })

    // const setFormData = (data: any) => {

    //     console.log("Setting form data", data);
    //     if (!data.transactionType) throw new Error("Transaction type is required");
    //     seFormData(data);
    // }

    // eslint-disable-next-line 
    const [customerPortfolios, setCustomerPortfolios] = useState<Portfolio[]>([])  //TODO: why is customerPortfolios unused to populate the combobox?
    const [portfolios, setPortfolios] = useState<Portfolio[]>([])
    const [displayedPortfolios, setDisplayedPortfolios] = useState<Portfolio[]>([])
    const [custodianAccounts, setCustodianAccounts] = useState<CustodianAccount[]>([])
    const [custodianAccountsWithBalance, setCustodianAccountsWithBalance] = useState<CustodianAccountWithBalance[]>([])

    const functions = useFunctions();

    const [getCashLoading, setGetCashLoading] = useState<boolean | string>(false);
    const [loadingPortfolios, setLoadingPortfolios] = useState(false);

    const [customerData, setCustomerData] = useState<(Customer & CustomAutocomplete)[]>([]);

    const db = useFirestore();


    // useEffect(() => {
    //     form.setValues((prev: any) => ({ ...prev, ...formData }))
    // }, [formData])

    useEffect(() => {
        // if (ID) {


        if (!disableFetch) {

            setLoadingPortfolios(true);
            // const customerPortfoliosQuery = query(collection(db, "customers", ID as string, "portfolios"));
            //exclude GWI portfolios.  GWI portfolios have portfolioTypeID = 145
            let customerPortfoliosQuery = query(collectionGroup(db, "portfolios"), where('portfolioTypeID', '!=', 145));

            // if (ID) customerPortfoliosQuery = query(collection(db, "customers", ID as string, "portfolios"));


            onSnapshot(customerPortfoliosQuery, (snapshot) => {
                if (snapshot.metadata.fromCache) {
                    console.log("Cached for customer portfolios ", snapshot.docs.length);
                    return;
                } else {
                    console.log("Server data for portfolios", snapshot.docs.length);
                }

                let portfolioRows = snapshot.docs.map((doc) => {
                    let parent = doc.ref.parent?.parent?.id;
                    // console.log("Parent", parent);
                    const data = doc.data() as Portfolio;
                    data["text_l"] = data.code;
                    data["text_r"] = `${data.portfolioType} `
                    data["value"] = data.code!;
                    data["key"] = doc.id;
                    data["label"] = `${data["text_l"]} - ${data["text_l"]}  `
                    data["id"] = (doc.id).toString();
                    data["group"] = "Other portfolios"
                    data["parent_id"] = parent;  //customer ID
                    return data;
                });
                setPortfolios(portfolioRows);
                setDisplayedPortfolios(portfolioRows);
                setLoadingPortfolios(false);
                console.log("We set displayedPortfolios from disableFetch effect!", disableFetch, displayedPortfolios.length)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [disableFetch])


    useEffect(() => {
        if (!disableFetch) {
            // let custodians = [] as any;
            let custodiansObject = {} as Record<string, CustodianAccountWithBalance> //just to help me create the array properly, even though it might have been wiser to use an object from the beginning

            if (form.values.position) {

                console.log("We got pos");
                let position = form.values.position;

                let type = form.values.transactionType;


                //filter out based on investment code.
                let codeNeeded = "CASH"
                if (type === "security_out") {
                    codeNeeded = "CS";
                }

                for (let row of position) {
                    // console.log("Row", row._INVESTMENT_INVESTMENTTYPE_CODE_, codeNeeded, row.MARKETVALUELOCALEND, row.CUSTODIANACCOUNT)
                    if (row._INVESTMENT_INVESTMENTTYPE_CODE_.toUpperCase() === codeNeeded) {

                        if (row.MARKETVALUELOCALEND <= 0) continue;

                        let custodian = { ...custodianAccounts?.find((custodian) => custodian.id === row.CUSTODIANACCOUNT) } as CustodianAccountWithBalance;
                        if (custodian && custodian.id) {
                            //copy the custodian so we don't change the elements in custodianAccounts, we don't want balances stuck there.

                            //does he exist in the first place?
                            let existingCustodian = custodiansObject[custodian.id];

                            if (existingCustodian) {
                                custodian = existingCustodian;
                            }
                            // else {
                            // custodian = { ...custodian };
                            // }


                            custodian.balance = custodian.balance ? row.MARKETVALUELOCALEND + custodian.balance : row.MARKETVALUELOCALEND;
                            custodian.unitCost = row.UNITCOSTBOOKEND;
                            custodian.text_r = `${custodian.balance.toLocaleString()} ${custodian._UDF_CCY_}`
                            custodian.label = <><Text fw={700}>{`${custodian.id}`}</Text><Text  >{`${custodian.balance.toLocaleString()} ${custodian._UDF_CCY_}`}</Text></>
                            custodian.value = custodian.id!;

                            custodiansObject[custodian.id!] = custodian;
                            // custodians.push(custodian);
                        }
                        else {
                            console.warn("Can't find custodian account", row.CUSTODIANACCOUNT);
                        }
                    }
                }
                /*
                            let trialBalanceRows = form.values.trialBalance as TrialBalanceRow[];
                
                            
                
                
                            for (let row of trialBalanceRows) {
                                if (row.SECT.toLowerCase() === "assets" && row.DETAIL.toLowerCase() === "cost") {
                                    let custodianName = row.SUBCAT;
                                    //I'm really supposed to use a map here to make this much faster, but the arrays aren't really big in size.
                                    let custodian = custodianAccounts.find((custodian: any) => custodian.id === custodianName);
                                    if (custodian) {
                                        //copy the custodian so we don't change the elements in custodianAccounts, we don't want balances stuck there.
                                        custodian = { ...custodian };
                                        custodian.balance = row.TEMPCLOSINGBAL.toFixed(3);
                                        console.log(custodian);
                                        custodian.text_r = `${row.TEMPCLOSINGBAL.toLocaleString()} ${custodian._UDF_CCY_}`
                
                
                                        custodians.push(custodian);
                                    }
                                    else {
                                        console.warn("Can't find custodian account", custodianName);
                                    }
                
                                }
                            }
                */

            }

            setCustodianAccountsWithBalance(Object.values(custodiansObject));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [custodianAccounts, form.values.position, disableFetch]);

    useEffect(() => {
        let ID = form.values.customerID;

        console.log("Checking portfolios for ID ", ID);

        if (ID) {
            console.log("We got ID yeah?");

            //find customer from ID
            let customer = customerData?.find((customer: any) => customer.id === ID);

            console.log("customerData We got ID yeah?", customerData);

            if (customer)
                setCustomerObj(customer);
            //loop through portfolios and insert the ones that match the ID using for loop
            let customerPortfoliosRows = portfolios.filter((portfolio: any) => portfolio.parent_id === ID) || [];
            customerPortfoliosRows?.forEach((portfolio: any) => {
                portfolio["group"] = "Portfolios of " + ID;
            });
            setCustomerPortfolios(customerPortfoliosRows);
            setDisplayedPortfolios([...new Set([...customerPortfoliosRows, ...portfolios])]);
            // setDisplayedPortfolios([...customerPortfoliosRows]);
            // console.log("We set displayedPortfolios from customerID effect!", displayedPortfolios.length)

            console.log("We got customer portfolios", customerPortfoliosRows);
        }
        else {
            setCustomerObj(null);

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form.values.customerID, portfolios]);
    /*
        useEffect(() => {
    
            if (!disableFetch) {
                const capitalAccountsQuery = query(
                    collection(db, "capital_accounts"));
                onSnapshot(capitalAccountsQuery, (snapshot) => {
                    if (snapshot.metadata.fromCache) {
                        console.log("Cached data", snapshot.docs.length);
                        return;
                    } else {
                        console.log("Server data", snapshot.docs.length);
                    }
    
                    let capitalAccountsRows = snapshot.docs.map((doc) => {
                        var data = doc.data();
                        data["text_l"] = data.DESCRIPTION;
                        data["text_r"] = `${data.ACCOUNTBASETYPE} `
                        data["value"] = data.DESCRIPTION;
                        data["key"] = doc.id;
                        data["id"] = (doc.id).toString();
                        return data;
                    });
                    setCapitalAccounts(capitalAccountsRows);
    
    
                });
                if (!custodianAccountsParent) {
                    const custodianAccountsQuery = query(
                        collection(db, "custodian_accounts"),);
                    onSnapshot(custodianAccountsQuery, (snapshot) => {
                        if (snapshot.metadata.fromCache) {
                            console.log("Cached data", snapshot.docs.length);
                            return;
                        } else {
                            console.log("Server data", snapshot.docs.length);
                        }
    
                        let custodianAccountsRows = snapshot.docs.map((doc) => {
                            var data = doc.data();
                            data["id"] = (doc.id).toString();
                            data["text_r"] = data._CUSTODIAN_NAMESORT_;
                            data["text_l"] = data.NAMESORT;
                            data["key"] = doc.id;
                            data["value"] = data.NAMESORT;
                            return data;
                        });
    
                        setCustodianAccounts(custodianAccountsRows);
    
    
    
                    });
                }
            }
    
        }, [])
    */
    useEffect(() => {
        setCustodianAccounts(custodianAccountsParent);

    }, [custodianAccountsParent])


    const setCustomerObj = (item: Customer | null) => {
        console.log("Setting form data to ", item);
        if (item)
            form.setValues((prev) => ({
                ...prev,
                "customerID": item?.id,
                "name_en": item?.name_en,
                "name_ar": item?.name_ar,
                "email": item?.email,
                "mobile": item?.mobile,
                "customerObj": item
            }))

        else {
            form.setValues((prev: any) => ({
                ...prev,
                "customerID": item,
                "name_en": item,
                "name_ar": item,
                "email": item,
                "mobile": item,
                "customerObj": item
            }))
        }


        // setFormData({
        //     ...formData,
        //     "customerID": item.id,
        //     "name_en": item.name_en,
        //     "name_ar": item.name_ar,
        //     "email": item.email,
        //     "mobile": item.mobile,
        //     "customerObj": item
        // })
    }

    // useEffect(() => {
    //     if (!disableFetch) {
    //         const customerQuery = query(
    //             collection(db, "customers"),);
    //         return onSnapshot(customerQuery, (snapshot) => {
    //             if (snapshot.metadata.fromCache) {
    //                 console.log("Cached data", snapshot.docs.length);
    //                 // return;
    //             } else {
    //                 console.log("Server data", snapshot.docs.length);
    //             }

    //             let rows = snapshot.docs.map((doc) => {
    //                 var data = doc.data() as Customer & CustomAutocomplete;
    //                 data["id"] = doc.id;
    //                 data["key"] = doc.id;
    //                 data["text_l"] = data.id;
    //                 data["text_r"] = data.name_ar;
    //                 data["value"] = data.civilID;
    //                 data["label"] = `${data["text_l"]} - ${data["text_l"]}`;
    //                 // data["item"] = data;

    //                 //set expiry date and stuff if low/high risk

    //                 return data;
    //             });
    //             setCustomerData(rows);

    //         });
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [disableFetch])


    const getPosition = (portfolio: any) => {

        console.log("Getting position!", form.values);
        setGetCashLoading(portfolio.value);

        const getPortfolioPositionWithCustodianAccounts = httpsCallable(functions, "getPortfolioPositionWithCustodianAccounts");
        //get cash on hand for this portfolio
        let requestData: any = { portfolioCode: portfolio.value }
        //check if there's a date selected.  If so, add it to the request.
        if (form.values.custom_date) {
            requestData["date"] = form.values.transactionTimestamp;
            requestData["custom_date"] = true;
        }

        getPortfolioPositionWithCustodianAccounts(requestData).then((position: any) => {
            console.log("position available is ", position);

            setGetCashLoading(false)
            // form?.setValues((prev: any) => ({ ...prev, position: position.data.rows, trialBalance: position.data.trialBalance }))
            form?.setValues((prev: any) => ({ ...prev, position: position.data.rows }))

        })
    }


    // console.log("le portfolio", form.values.portfolio?.value, form.values.temp?.portfolio, displayedPortfolios.length);

    //if I don't do this, the query runs on every render.
    // let customerQuery = useRef<any>(query(collection(db, "customers")));

    const civilIDRef = useRef<any>();
    const portfolioRef = useRef<any>();

    // const customerSelectMEmo = useMemo(() => , [])

    return (
        <>
            <Grid.Col span={{ xs: 12, lg: 6 }}>

                <CustomerSelectComponent
                    customerData={customerData}

                    setCustomerData={setCustomerData}
                    defaultValue={form.values.customerID}
                    ref={civilIDRef}

                    textProps={{
                        label: "Civil ID or Commercial Registry",
                        placeholder: "Enter Civil ID or Commercial Registry",
                        disabled: disabled
                    }}
                    handleSubmit={(customerID) => {
                        console.log("We set ID to", customerID);
                        form.setFieldValue("customerID", customerID as string);
                    }}
                />

                {/* <CustomerSelectComponent
                    setCustomerData={setCustomerData}
                    defaultValue={form.values.customerID}
                    ref={civilIDRef}

                    textProps={{
                        label: "Civil ID or Commercial Registry",
                        placeholder: "Enter Civil ID or Commercial Registry",
                        disabled: disabled

                    }}
                    handleSubmit={(customerID: string) => {
                        console.log("We set ID to", customerID);
                        form.setFieldValue("customerID", customerID);
                        // setID(obj.value);
                        // setCustomerObj(obj);
                    }}
                />
                
                
                */}
                {/* <AutocompleteComponent
                    // {...form.getInputProps("customerID")}
                    ref={civilIDRef}
                    // propsPath="customerID"
                    defaultItem={form.values.customerObj}
                    textProps={{
                        label: "Civil ID or Commercial Registry",
                        placeholder: "Enter Civil ID or Commercial Registry",
                        disabled: disabled

                    }}
                    disabled={disabled}

                    // displayKey={"civilID"}
                    customFilter={(value: string, item: Customer) => {
                        return item.civilID.startsWith(value) || item.name_en?.toLowerCase().includes(value.toLowerCase()) || item.name_ar?.toLowerCase().includes(value.toLowerCase());
                    }}
                    handleSubmit={(obj: Customer) => {
                        console.log("We set ID to", obj);
                        form.setFieldValue("customerID", obj.civilID);
                        // setID(obj.value);
                        // setCustomerObj(obj);
                    }}

                    data={customerData}
                    text_r="name_ar"
                    text_l="id"
                    value="civilID"
                    queryStatement={query(
                        collection(db, "customers"),)}
                    filterBy="civilID"
                /> */}

            </Grid.Col>


            <Grid.Col span={{ xs: 12, lg: 6 }}>
                <TextInput

                    {...form.getInputProps("name_en")}
                    label="Name (English)"
                    placeholder=""
                    disabled
                    value={form.values?.name_en || ""}


                />
            </Grid.Col>
            <Grid.Col span={{ xs: 12, lg: 6 }}>
                <TextInput
                    {...form.getInputProps("name_ar")}
                    label="Name (Arabic)"
                    placeholder=""
                    disabled
                    value={form.values?.name_ar || ""}



                />
            </Grid.Col>




            <Grid.Col span={{ xs: 12, lg: 6 }}>
                <AutocompleteComponent
                    // {...form.getInputProps("portfolio")}
                    disabled={disabled}
                    ref={portfolioRef}
                    defaultItem={form.values.portfolio}
                    textProps={{
                        label: "Portfolio ID ",
                        placeholder: "Enter portfolio ID",
                        rightSection: getCashLoading || loadingPortfolios ? <><Loader size="xs" /><Text size="xs">{getCashLoading ? "Getting positions" : "Getting portfolios"}</Text></> : undefined,
                        disabled: disabled


                    }}
                    // value={disabled ? form.values.portfolio?.value || "" : form.values.temp?.portfolio || ""}
                    // itemComponent={AutoCompleteItem}
                    customFilter={(value: any, item: { code: string | any[]; }) => {
                        return item.code.includes(value)  //only really need to search the code.

                    }}
                    // displayKey={"investmentPortfolio"}
                    // rightSectionWidth={150}
                    data={displayedPortfolios}
                    text_r=""
                    value=""
                    isGrouped={true}
                    handleSubmit={

                        (portfolio: Portfolio) => {
                            // const portfolio = displayedPortfolios.find((obj: Portfolio) => obj.value === portfolioID);

                            if (!portfolio) {
                                console.error("How is it that we can't find a portfolio we just chose?", displayedPortfolios, portfolio);
                                return;
                            }


                            //is it the same one we selected previously?
                            let refetch = true;

                            if (portfolio.code === form.values?.portfolio?.value) {
                                refetch = false;
                            }

                            console.log("Selected", portfolio);

                            form.setFieldValue("portfolio", portfolio);

                            if (!refetch) {
                                return;
                            }
                            form.setFieldValue("position", undefined);
                            form.setFieldValue("custodianAccount", undefined)


                            if (portfolio.parent_id !== form.values.customerID) {


                                civilIDRef.current.submitItem(portfolio?.parent_id);

                                // form.setFieldValue("customerID", portfolio.parent_id);
                                // setID(portfolio.parent_id)
                                console.log("WE SET NEW ID!", portfolio.parent_id);
                                //get customer data.

                            }
                            //get cash on hand for this portfolio

                            console.log("Checking position for portfolio code ", portfolio)

                            //is it the same portfolio?  if so, don't do anything.  Otherwise, send another requet for the new portfolio.
                            if (getCashLoading && getCashLoading === portfolio.value) return;


                            getPosition(portfolio);


                        }}

                />
            </Grid.Col>



            <Grid.Col span={{ xs: 8, lg: 3 }} >
                <DateTimePicker
                    disabled={form.values.portfolio && Object.keys(form.values.portfolio)?.length ? (disabled || !form.values.custom_date) : true}


                    firstDayOfWeek={0}
                    weekendDays={[5, 6]}
                    label="Pick date and time"
                    placeholder="Pick date and time"
                    withSeconds
                    valueFormat="DD MMM YYYY HH:mm:ss"
                    mx="auto"

                    maxDate={new Date()}

                    dropdownType="modal"
                    submitButtonProps={{
                        onClick: () => {
                            console.log("Done!");
                            //get the position again
                            if (form.values.portfolio) {

                                getPosition(form.values.portfolio);
                            }

                        }
                    }}
                    value={form.values?.transactionTimestamp ? (form.values.transactionTimestamp as Timestamp)?.toDate() : new Date() || new Date()}
                    onChange={(value) => {
                        form?.setValues((prev: any) => ({
                            ...prev,
                            "transactionTimestamp": Timestamp.fromDate(value as Date),
                        }))

                        // console.log("Date changed to", value);
                    }}

                />
            </Grid.Col>
            <Grid.Col span={{ xs: 4, lg: 3 }} style={{ marginTop: 25 }} >

                <Checkbox
                    disabled={disabled}
                    label="Custom Date/Time"
                    description="Need to choose portfolio first to customize the date"
                    {...form.getInputProps('custom_date')}
                // checked={usePACI}
                // onChange={(e) => setUsePACI(e.target.checked)}
                />

            </Grid.Col>

            {form.values.position &&


                <Grid.Col span={{ xs: 12, lg: 12 }}>
                    <Table
                        stickyHeader
                        title='current position'
                        withTableBorder={true}
                        withColumnBorders={true}
                        withRowBorders={true}

                        style={
                            {
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",

                            }
                        }

                    >

                        <Table.ScrollContainer
                            // mah={"40%"}
                            minWidth={"100%"}

                            style={{

                                width: "100%",
                                height: form.values.position ? "50vh" : "",
                                overflow: "scroll",

                            }}
                        >
                            <Table.Thead>
                                <Table.Tr>
                                    <Table.Th>COST BOOKEND</Table.Th>
                                    <Table.Th>COST LOCAL END</Table.Th>
                                    <Table.Th>CUSTODIAN ACCOUNT</Table.Th>
                                    <Table.Th>CUSTODIAN ACCOUNT CURRENCY</Table.Th>
                                    <Table.Th>MARKET VALUE BOOK END</Table.Th>
                                    <Table.Th>MARKET VALUE LOCAL END</Table.Th>
                                    <Table.Th>QTY END</Table.Th>
                                    <Table.Th>UNIT COST BOOK END</Table.Th>
                                    <Table.Th>DENOMINATION CODE</Table.Th>
                                    <Table.Th>INVESTMENT DESCRIPTION</Table.Th>
                                    <Table.Th>INVESTMENT INVESTMENTTYPE CODE</Table.Th>
                                    <Table.Th>INVESTMENT INVESTMENTTYPE DESCRIPTION</Table.Th>
                                </Table.Tr>
                            </Table.Thead>
                            {
                                form.values.position?.length ?
                                    <Table.Tbody>{(form.values.position || [])?.map((element: Investment, idx) => (


                                        <Table.Tr key={idx}>
                                            <Table.Td>{element.COSTBOOKEND}</Table.Td>
                                            <Table.Td>{element.COSTLOCALEND}</Table.Td>
                                            <Table.Td>{element.CUSTODIANACCOUNT}</Table.Td>
                                            <Table.Td>{element.CUSTODIANACCOUNTCURRENCY}</Table.Td>
                                            <Table.Td>{element.MARKETVALUEBOOKEND}</Table.Td>
                                            <Table.Td>{element.MARKETVALUELOCALEND}</Table.Td>
                                            <Table.Td>{element.QTYEND}</Table.Td>
                                            <Table.Td>{element.UNITCOSTBOOKEND}</Table.Td>
                                            <Table.Td>{element.UNITCOSTLOCALEND}</Table.Td>
                                            <Table.Td>{element._DENOMINATION_CODE_}</Table.Td>
                                            <Table.Td>{element._INVESTMENT_DESCRIPTION_}</Table.Td>
                                            <Table.Td>{element._INVESTMENT_INVESTMENTTYPE_CODE_}</Table.Td>
                                            <Table.Td>{element._INVESTMENT_INVESTMENTTYPE_DESCRIPTION_}</Table.Td>
                                        </Table.Tr>
                                    ))}</Table.Tbody>
                                    :

                                    <Table.Tbody>
                                        <Table.Tr>

                                            No Data
                                        </Table.Tr>
                                    </Table.Tbody>
                            }
                        </Table.ScrollContainer>
                    </Table>





                </Grid.Col>


            }
            <Grid.Col span={{ xs: 6, lg: 6 }}>


                {
                    form.values.transactionType !== "journal_entry" &&
                    <AutocompleteComponent
                        // {...form.getInputProps("custodianAccount")}

                        disabled={disabled || ((form.values.transactionType === "cash_out" || form.values.transactionType === "security_out") && custodianAccountsWithBalance.length === 0)}

                        // comboboxProps={{ withinPortal: true }}

                        // searchable
                        // nothingFound={"Nothing Found"}
                        defaultItem={form.values.custodianAccount ? form.values.custodianAccount : form.values.custodianAccount}
                        textProps={{
                            error: (form.values.transactionType === "cash_out" || form.values.transactionType === "security_out") && custodianAccountsWithBalance.length === 0 ? "No custodian accounts with balance" : null,
                            label: `${form.values.transactionType === "fx" ? "From" : ""} Custodian Account`,
                            placeholder: "KFH - 000 - KWD",
                            disabled: disabled || ((form.values.transactionType === "cash_out" || form.values.transactionType === "security_out") && custodianAccountsWithBalance.length === 0),
                            defaultValue: form.values.custodianAccount?.value
                        }}

                        // itemComponent={AutoCompleteItem}



                        // value={form.values.custodianAccount.value}
                        customFilter={(value: string, item: any) => {
                            return JSON.stringify(item)?.toLowerCase().includes(value?.toLowerCase() || "")
                        }}

                        //TODO:  Need to rethink this.  Far too many conditions.  I say we store a "visible_for" array in the custodian account and use that to filter.
                        data={
                            form.values.transactionType === "fx" ||
                                form.values.transactionType === "cash_out" ||
                                form.values.transactionType === "security_out" ? custodianAccountsWithBalance :
                                custodianAccounts.filter((custodian: any) => form.values.transactionType === "security_in" ? custodian?.securities_visible === true : custodian?.visible === true)
                        }
                        text_r=""
                        value=""
                        handleSubmit={
                            (custodianAccount: CustodianAccount) => {


                                if (custodianAccount) {
                                    let currency = custodianAccount._UDF_CCY_;

                                    //get the capital account associated with that currency account

                                    form.setValues((prev: any) => ({
                                        ...prev,
                                        custodianAccount: custodianAccount,
                                        currencyFromValue: currency,
                                        currency,

                                    }))
                                }


                            }
                        }
                    //Select component onChange
                    // onChange={

                    //     (custodianAccountSelect: any) => {

                    //         const custodianAccount =
                    //             form.values.transactionType === "fx" || form.values.transactionType === "cash_out" || form.values.transactionType === "security_out" ?
                    //                 custodianAccountsWithBalance.find((custodianAccountObj: any) => custodianAccountObj.value === custodianAccountSelect) : custodianAccounts.filter((custodian: any) => custodian.visible === true).find((custodianAccountObj: any) => custodianAccountObj.value === custodianAccountSelect)
                    //             ;
                    //         let currency = custodianAccount._UDF_CCY_;


                    //         //get the capital account associated with that currency account

                    //         const tempData = { ...form.values.temp, custodianAccount: custodianAccount.id }
                    //         form.setValues((prev: any) => ({
                    //             ...prev,
                    //             "custodianAccount": custodianAccount,
                    //             currencyFromValue: currency,
                    //             currency,

                    //         }))


                    //     }
                    // }
                    // limit={custodianAccounts.length}

                    // sx={{ maxHeight: 200, overflow: 'auto' }}
                    />
                }
            </Grid.Col>
            {form.values.transactionType === "fx" &&
                <Grid.Col span={{ xs: 6, lg: 6 }}>


                    <AutocompleteComponent

                        disabled={disabled}

                        // comboboxProps={{ withinPortal: true }}

                        // searchable
                        // nothingFound={"Nothing Found"}
                        defaultItem={form.values.newCustodianAccount?.value}
                        textProps={{
                            label: `To Custodian Account`,
                            placeholder: "KFH - 000 - KWD",
                            disabled: disabled,
                            defaultValue: form.values.newCustodianAccount?.value
                        }}

                        // itemComponent={AutoCompleteItem}

                        // value={form.values.custodianAccount.value}
                        customFilter={(value: string, item: any) => {
                            return JSON.stringify(item)?.toLowerCase().includes(value?.toLowerCase() || "")
                        }}

                        //TODO:  Need to rethink this.  Far too many conditions.  I say we store a "visible_for" array in the custodian account and use that to filter.
                        data={custodianAccounts.filter((custodian: any) => custodian?.visible === true)}
                        text_r=""
                        value=""
                        handleSubmit={
                            (newCustodianAccount: CustodianAccount) => {


                                if (newCustodianAccount) {
                                    let currency = newCustodianAccount._UDF_CCY_;

                                    //get the capital account associated with that currency account

                                    form.setValues((prev: any) => ({
                                        ...prev,
                                        newCustodianAccount: newCustodianAccount,
                                        currencyToValue: currency,
                                    }))
                                }


                            }
                        }
                    />
                </Grid.Col>
            }

            {form.values.transactionType !== "fx" &&
                <Grid.Col span={{ xs: 6, lg: 6 }}>
                    {/* <Autocomplete
                        {...form.getInputProps("capitalAccount")}

                         comboboxProps={{ withinPortal: true }}
                        disabled={disabled}

                        nothingFound={"Nothing Found"}
                        // value={value}
                        label="Capital Account "
                        // disabled={disabled}
                        // defaultValue={ID}
                        onChange={(value) => {
                            const tempData = { ...form.values.temp, capitalAccount: value }
                            form.setValues((prev: any) => ({
                                ...prev,
                                temp: tempData
                            }))

                            // setFormData({ ...formData, temp: tempData })
                        }}

                        value={disabled ? form.values.capitalAccount?.value || "" : form.values?.temp?.capitalAccount}
                        onItemSubmit={

                            (value) => {
                                const tempData = { ...form.values.temp, capitalAccount: value.value }
                                form.setValues((prev: any) => ({
                                    ...prev,
                                    "capitalAccount": value,
                                    temp: tempData
                                }))
                            }
                        }
                        placeholder="000000"
                        // itemComponent={AutoCompleteItem}
                        data={capitalAccounts}
                        filter={(value, item) => {
                            return JSON.stringify(item).toLowerCase().includes(value.toLowerCase())
                        }}
                        limit={capitalAccounts.length}
                        dropdownComponent={({ children }: any) => <ScrollArea
                            h={200}
                            w={"100%"}
                        >
                            {children}

                        </ScrollArea>}
                    /> */}
                </Grid.Col>
            }

        </ >
    )
}

export default TransactionsPanel
