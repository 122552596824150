import React from "react";

import { civilValid, getCountriesList } from "../utils";
import { httpsCallable } from "firebase/functions";
import { useForm } from "@mantine/form";
import {

  Alert,
  Box,
  Button,
  Group,
  Radio,
  Select,
  SimpleGrid,
  Space,
  Stack,
  TextInput,
} from "@mantine/core";

import { IconAlertCircle } from "@tabler/icons-react";
import { AddClientRequest, BaseResult, Customer } from "../types";
import { useFunctions } from "../helpers/firebaseContext";


export interface RequestKYCUpdateProps {
  setModal?: (value: boolean) => void;
  onClose?: () => void;
  civilID?: string;
  customer?: Customer;
  isUpdate?:boolean;
}

export default function RequestKYCUpdate({ civilID, onClose, setModal, ...props }: RequestKYCUpdateProps) {
  const functions = useFunctions();
  // const { setModal } = props;
  // const civilID = props?.civilID || "";
  // const onClose = props?.onClose;
  civilID = civilID || "";
  const [pending, setPending] = React.useState(false);

  const [msg, setMsg] = React.useState(null as any);
  const [isError, setIsError] = React.useState(false);

  const form = useForm<AddClientRequest>({
    initialValues: {
      name_en: props.customer?.name_en??"",
      name_ar: props.customer?.name_ar??"",
      mobile: props.customer?.mobile??"",
      email: props.customer?.email??"",
      civilID: props.customer?.civilID??"",
      nationality: props.customer?.nationality??"KW",
      gender: props.customer?.gender??"",
      customerType: "individual" // TODO: this is temporary until we start using companies or different customer types,
    },

    validate: {
      name_en: (value) =>
        value.length < 2 ? "Name must have at least 2 letters" : null,
      name_ar: (value) =>
        value.length < 2 ? "Name must have at least 2 letters" : null,
      email: (value) => {
        if (value.length === 0) return null;
        return (/^\S+@\S+$/.test(value) ? null : "Invalid email");
      },
      mobile: (value) => (value.length !== 8 ? "Incorrect mobile" : null),
      civilID: (value) => (!value || !civilValid(value) ? "Invalid civil ID" : null),  //TODO: This will need to be updated for different CustomerType
      nationality: (value) => (getCountriesList().filter((country) => { return country.value === value }).length <= 0 ? "Nationality is mandatory" : null),
      gender: (value) => (value !== "M" && value !== "F" ? "Gender is mandatory" : null),
    },
  });
  const handleSubmit = async (values: Omit<AddClientRequest, "id">, event: any) => {
    event.preventDefault();
    setPending(true);
    values.isUpdate = props.isUpdate;

    try {
      const addClientFunction = httpsCallable<AddClientRequest, BaseResult>(functions, "addClient");

      //set type to submit because we want the user to create an entirely new KYC
      await addClientFunction(values);

      // e.target.reset();
      setMsg(<>{props.isUpdate? "Client Updated successfully." : "Client Added successfully."}</>);
      setIsError(false);
      setTimeout(() => {
        if (onClose) {
          onClose()
        }
        if (setModal) {
          setModal(false)
        }
      }

        , 2000);
    } catch (error: any) {
      setIsError(true);
      console.log(error);
      setMsg(error.message);
    }

    setPending(false);
  };
  return (
    <Stack>
      <form
        onSubmit={form.onSubmit((values, _event) =>
          handleSubmit(values, _event)
        )}
        onChange={() => setMsg(null)}
      >
        <SimpleGrid cols={1}>
          <TextInput
            // withAsterisk
            label="Civil ID"
            disabled={props.isUpdate}
            placeholder="Enter Civil ID"
            {...form.getInputProps("civilID")}
          />
          {/* <SimpleGrid cols={2}> */}
          <TextInput
            // withAsterisk
            label="Name (English)"
            placeholder="Joe"
            {...form.getInputProps("name_en")}
          />
          <TextInput
            // withAsterisk
            label="Name (Arabic)"
            placeholder="جو"
            {...form.getInputProps("name_ar")}
          />
          {/* </SimpleGrid> */}
          <TextInput
            // withAsterisk
            label="Email"
            placeholder="their@email.com"
            {...form.getInputProps("email")}
          />
          <TextInput
            // withAsterisk
            label="Mobile"
            placeholder="Enter mobile number"
            {...form.getInputProps("mobile")}
          />
          <Select
            label="Nationality"
            data={getCountriesList()}
            {...form.getInputProps("nationality")}
          />

          <Radio.Group
            name="gender"
            label="Gender"
            {...form.getInputProps("gender")}
          >
            <Group mt="xs">
              <Radio value="M" label="Male" />
              <Radio value="F" label="Female" />

            </Group>
          </Radio.Group>
        </SimpleGrid>

        <Box
          maw={300}
          mx="auto">
          <Space h="xl" />
          {!!msg ? ( //love my check if it's an error or not.  Please don't do this, it's just a proof of concept page.
            <Alert
              icon={<IconAlertCircle size={16} />}
              color={isError ? "red" : "green"}
              title={isError ? "Failure" : "Success"}
            >
              {msg}
            </Alert>
          ) : null}
          <Group justify="center" mt="md">
            <Button type="submit" loading={pending}>
              {props.isUpdate? "Update Client" : "Add Client"}
            </Button>
          </Group>
        </Box>
      </form>
    </Stack>
  );
}
