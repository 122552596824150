import { useForm } from '@mantine/form';
import { useContext, useEffect, useState } from 'react'
import { AddKFHRmRequest, BaseResult, CustomClaims, NotificationMSG, RmAuthResult, RMUser, SegmentType } from '../../types';
import { HttpsCallableResult, httpsCallable } from 'firebase/functions';
import { Alert, Button, Checkbox, Grid, Group, Select, Space, TextInput } from '@mantine/core';
import { IconAlertCircle } from '@tabler/icons-react';
import { FirebaseError } from 'firebase/app';

import { MyUserDataContext } from '../../contexts';

import PasswordStrengthComponent, { passwordRequirements } from './PasswordStrengthComponent';
import { useFirestore, useFunctions } from '../../helpers/firebaseContext';
import { RmsSelectComponent } from '../../components/rms_select_component';
import { doc, onSnapshot } from 'firebase/firestore';

interface AddRmFormType {
    rmUser?: RMUser | null
    handleSubmit: () => void
}
const AddRmForm = ({ rmUser, handleSubmit }: AddRmFormType) => {
    const userContext = useContext(MyUserDataContext);
    let userClaims = userContext.firebaseTokenResult?.claims.customClaims as CustomClaims || {};

    const functions = useFunctions();
    const [msg, setMsg] = useState<NotificationMSG>();
    const [loading, setLoading] = useState<boolean>(false)
    const [orderHandler, setOrderHandler] = useState<RMUser | undefined>();
    const db = useFirestore();


    // useEffect(() => {
    //     if (rmUser) {
    //         setLoading(true);
    //         const getAndSyncRmAuthData = httpsCallable<string, RmAuthResult>(functions, "getAndSyncRmAuthData");
    //         getAndSyncRmAuthData(rmUser?.email)
    //             .then((result) => {
    //                 form.setFieldValue("active", result.data.active ?? rmUser.active);
    //                 form.setFieldValue("twoFactorEnabled", result.data.twoFactorEnabled ?? rmUser.twoFactorEnabled);
    //             })
    //             .finally(() => {
    //                 console.log("Done from sync");
    //                 setLoading(false)
    //             });
    //     }

    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [])

    //get RMs
    useEffect(() => {

        if (!rmUser?.order_handler_email) {
            return;
        }

        const rmDoc = doc(db, "rms", rmUser?.order_handler_email.toLowerCase());
        // let q = query(rmCollection, where("active", "==", true));

        //is it a KFH RM or operator?  If so, limit it to the segment
        // if (userClaims["kfh_rm"] || userClaims["kfh_oper"]) {
        //   q = query(q, where("segment", "==", userClaims.segment));  //TODO: we need the RM segment asap!
        // }


        return onSnapshot(rmDoc, (snapshot) => {
            if (snapshot.metadata.fromCache) {
                console.log("RM Cached data", snapshot.exists());
                // return;
            } else {
                console.log("RM Server data", snapshot.exists());
            }

            // let dict = {} as any;
            let rm: RMUser | undefined = undefined;

            if (snapshot.exists()) {
                rm = snapshot.data() as RMUser;
            }



            setOrderHandler(rm);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rmUser?.order_handler_email]);



    const form = useForm<AddKFHRmRequest>({
        initialValues: {
            email: rmUser?.email?.toLowerCase() || "",
            emailVerified: true,
            phoneNumber: rmUser?.mobile || "",
            mobile: rmUser?.mobile || "",
            displayName: rmUser?.name_en || "",
            disabled: false,
            name_ar: rmUser?.name_ar || "",
            name_en: rmUser?.name_en || "",
            segment: rmUser ? rmUser?.segment : (userClaims.segment as SegmentType),
            role: rmUser?.role || "rm",
            rm_id: rmUser?.rm_id,
            active: rmUser?.active,
            twoFactorEnabled: rmUser?.twoFactorEnabled,
            certified: rmUser?.certified,
            order_handler_email: rmUser?.order_handler_email,

        },

        validate: {
            email: (val) => (/^\S+@\S+$/.test(val) ? null : 'Invalid email'),
            password: (val?: string) => {
                let err: string | null = null

                if (!rmUser) {
                    if (!val)
                        err = "Please enter a valid password"
                    if (val?.length! <= 7)
                        err = 'Password should include at least 8 characters'


                    passwordRequirements.forEach((requirement: any) => {
                        if (!requirement.re.test(val)) {
                            err = requirement.label as (string)
                        }
                    });
                }
                return err



            },
            phoneNumber: (val) => (val && val.length !== 8 ? 'Phone number must consist of 8 numbers' : null),
        },
    });
    return (
        <div>

            <form

                onSubmit={form.onSubmit(async (formValues) => {
                    try {
                        setLoading(true)
                        let res: HttpsCallableResult<BaseResult>;
                        if (rmUser) {
                            const updateRM = httpsCallable<AddKFHRmRequest, BaseResult>(functions, "updateRM");
                            res = await updateRM(formValues);

                        }
                        else {

                            const addKFHRm = httpsCallable<AddKFHRmRequest, BaseResult>(functions, "addKFHRm");
                            res = await addKFHRm(formValues);

                        }
                        if (res.data.status === "success") {
                            setMsg({ type: "success", message: "Success" });
                            setLoading(false)

                            setTimeout(() => {
                                if (handleSubmit) {
                                    handleSubmit()
                                }
                            }

                                , 500);
                        }
                        else {
                            if (res?.data.message) {
                                setMsg({ type: "error", message: res.data.message });

                            }
                            else {

                                setMsg({ type: "error", message: "Something went wrong..." });
                            }
                        }
                    }
                    catch (e: any) {

                        if (e instanceof FirebaseError) {
                            setMsg({ type: "error", message: e.message });

                        }
                        else {
                            setMsg({ type: "error", message: "Something went wrong..." });

                        }
                    }

                    setLoading(false)



                })}
            >
                <TextInput
                    {...form.getInputProps("email")}
                    required
                    disabled={!!rmUser}
                    onChange={(event) => {
                        form.setFieldValue("email", event.target.value.toLowerCase())
                    }}
                    label="Email" />

                <TextInput
                    {...form.getInputProps("name_en")}
                    required
                    onChange={(event) => {
                        form.setFieldValue("name_en", event.target.value)
                        form.setFieldValue("displayName", event.target.value)
                    }}
                    label="Name (English)" />

                <TextInput
                    {...form.getInputProps("name_ar")}
                    required
                    label="Name (Arabic)" />

                <TextInput
                    {...form.getInputProps("rm_id")}
                    required
                    label="ID" />

                <TextInput
                    {...form.getInputProps("phoneNumber")}
                    required
                    onChange={(event) => {
                        form.setFieldValue("phoneNumber", event.target.value)
                        form.setFieldValue("mobile", event.target.value)
                    }}
                    label="Phone Number" />

                <Select
                    {...form.getInputProps("segment")}
                    label="Segment"
                    required
                    disabled={userClaims.admin != true}
                    data={["Ruwad", "Private Banking", "Tamayoz", "Individual Finance"]}
                />
                <Checkbox
                    mt={"xl"}
                    {...form.getInputProps("certified")}
                    checked={form.values.certified}
                    disabled={loading}
                    onChange={(event) => {
                        form.setFieldValue("certified", event?.currentTarget.checked);
                        if (!event?.currentTarget.checked) {
                            form.setFieldValue("order_handler_email", "");
                        }

                    }}
                    label="CMA Certified" />
                {/* NEED TO ADD DEFAULT VALUE BY EMAIL */}
                {!form.values.certified && <RmsSelectComponent


                    defaultValue={orderHandler?.name_en}
                    textProps={{
                        label: "Orders Handler:",
                        placeholder: "search RM by name or email",
                        disabled: loading || form.values.certified

                    }}
                    handleSubmit={(selectedRM: RMUser) => {
                        if (!selectedRM.certified) {
                            setMsg({
                                type: "error",
                                message: "Handler need to be certified"
                            });
                            form.setFieldValue("order_handler_email", "");
                        } else {
                            setMsg(undefined);
                            form.setFieldValue("order_handler_email", selectedRM.email);
                        }

                    }}

                />}

                {!rmUser &&
                    <PasswordStrengthComponent passwordValue={form.values.password || ""} passwordProps={form.getInputProps("password")} />

                }

                {rmUser &&
                    <>
                        <Checkbox
                            mt={"xl"}
                            {...form.getInputProps("active")}
                            checked={form.values.active}
                            disabled={loading}

                            label="Active" />

                        <Checkbox
                            mt={"xl"}
                            {...form.getInputProps("twoFactorEnabled")}
                            checked={form.values.twoFactorEnabled}
                            disabled={loading}

                            label="Use Two-Factor Authentication" />

                    </>
                }
                <Space h="md" />
                {msg && (
                    <Alert
                        icon={<IconAlertCircle size={16} />}
                        color={msg.type === "success" ? "green" : "red"}
                        title={msg.type === "success" ? "Success" : "Failure"}
                    >
                        {msg.message}
                    </Alert>
                )}
                <Space h="md" />

                <Group >
                    <Grid justify='end' style={{ width: "100%" }}>
                        <Grid.Col span={{ lg: 4 }}>

                            <Button type='submit' loading={loading}>

                                {rmUser ? "Update" : "Create"} Rm
                            </Button>
                        </Grid.Col>
                    </Grid>
                </Group>

            </form>

        </div >
    )
}

export default AddRmForm