import React, { ReactElement, useContext, useEffect, useState } from "react";
import {
  Text,
  Alert,
  Card,
  Space,
  Stack,
  TextInput,
  Title,
  Container,
  Checkbox,
  Grid,
  Divider,
  Group,
  Button,
  Select,
  Loader,
} from "@mantine/core";
import { UseFormReturnType, useForm } from "@mantine/form";
import { httpsCallable } from "firebase/functions";
import { IconAlertCircle, } from "@tabler/icons-react";




import { useDebouncedState } from "@mantine/hooks";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  query,
  where,

} from "firebase/firestore";

import {
  AppConfigContext,
  ConfigContext,
  MyUserDataContext,
} from "../../contexts";

import { Customer, CustomAutocomplete, RMUser, CustomClaims, AddServiceRequest, UpdateLink, ServiceType, ServiceTypeLabel, Service, SendServiceSMSRequest, BaseResult, } from "../../types";
import { CustomerSelectComponent } from "../../components/customer_select_component";

import { useNavigate } from "react-router-dom";
import { RmsSelectComponent } from "../../components/rms_select_component";
import { useFirestore, useFunctions } from "../../helpers/firebaseContext";
import RequestKYCUpdate from "../requestKYCUpdate";
import CustomModal from "../../components/custom_modal";
import { MessagesSent, refreshWhatsappTemplates } from "../../components/send_sms_modal";





const AddService = (props: any) => {

  const userContext = useContext(MyUserDataContext);
  const appConfigContext = useContext(AppConfigContext);

  const config = useContext(ConfigContext);

  let userClaims = userContext.firebaseTokenResult?.claims.customClaims as CustomClaims || {};

  const functions = useFunctions();
  const navigate = useNavigate();
  const db = useFirestore();

  const [pending, setPending] = React.useState(false);
  const [msg, setMsg] = React.useState<string | ReactElement | null>(null);
  const [SMSDocID, setSMSDocID] = useState<string>();
  const [createdService, setCreatedService] = useState<Service>();
  const [whastappTemplateLoading, setWhastappTemplateLoading] = useState<boolean>(true);

  const [isError, setIsError] = React.useState(false);
  const [customerID, setCustomerID] = useState<string | null>(null)
  const [customerObj, setCustomerObj] = useDebouncedState<Customer | undefined>(undefined, 200)
  const [onBehalfOfChecked, setOnBehalfOfChecked] = useState(false);
  const [customerData, setCustomerData] = useState<Customer[]>([]);
  const [customerCivilID, setCustomerCivilID] = useState<string>()
  const [toBeUpdatedCustomer, setToBeUpdatedCustomer] = useState<Customer | undefined>(undefined)
  const [addClientModal, setAddClientModal] = React.useState(false);
  const [useWhatsapp, setUseWhatsapp] = useState<boolean>();
  const [showSendOrderButton, setShowSendOrderButton] = useState(true);



  const form: UseFormReturnType<AddServiceRequest> = useForm<AddServiceRequest>({
    initialValues: {
      updateLink: {
        senderID: "KFHTrade"
      } as UpdateLink,
      onBehalfOf: undefined,
      action: "send_service_sms",
      service: {
        serviceType: "kfhtrade",
      }
    },

    validate: {

    },
  });

  const SERVICE_TYPES: ServiceTypeLabel[] = [
    { label: "KFH Trade", value: "kfhtrade" },
    { label: "KFH Brokrage", value: "kfhbrokerage" },
    { label: "Portfolio", value: "portfolio" },
  ];


  const getCustomerData = async (civilId: string) => {
    const customerSnapshot = await getDocs(query(collection(db, 'customers'), where('civilID', '==', civilId), limit(1)));
    const customerData = customerSnapshot.docs[0]?.data() as Customer;
    // await setCustomerID(civilId)
    if (customerData) {
      await setCustomerData([customerData])
      await setCustomerObj(customerData);
      if (customerData.civilID && (!customerData.gender || !customerData.nationality)) {
        setToBeUpdatedCustomer(customerData);
        form.setFieldValue("service.customerObj", customerData);
        setAddClientModal(true);
      } else {
        form.setFieldValue("service.customerObj", customerData);
        setToBeUpdatedCustomer(undefined);
        setAddClientModal(false);
      }
      return true;
    }
    else {
      setToBeUpdatedCustomer(undefined);
      setAddClientModal(true);
      return false;
    }
  }

  useEffect(() => {
    let ID = customerID;


    if (ID) {
      console.log("We got ID", ID);

      //find customer from ID
      let customer = customerData?.find((customer: any) => customer.id === ID);

      console.log("We got customer", customer);

      if (customer) {
        setCustomerObj(customer);
        if (customer.civilID && (!customer.gender || !customer.nationality)) {
          setToBeUpdatedCustomer(customer);
          setAddClientModal(true);
        } else {
          setToBeUpdatedCustomer(undefined);
        }
      } else {
        setToBeUpdatedCustomer(undefined);
      }
      form.setFieldValue("service.customerObj", customer);
    }
    else {
      setCustomerObj(undefined);
      setToBeUpdatedCustomer(undefined);
      form.setFieldValue("service.customerObj", undefined);

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerID]);


  useEffect(() => {
    const getData = async () => {

      await refreshWhatsappTemplates(true, form, appConfigContext.apiUrl!, () => { }, false, setWhastappTemplateLoading,
        (config?.serviceTemplates && form.values.service.serviceType) ? config?.serviceTemplates[form.values.service.serviceType]?.whatsappTemplate : undefined, "updateLink.selectedWhatsappTemplate",
      )

    }
    if (useWhatsapp) { getData() }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useWhatsapp])

  return (
    <Stack>
      <Title>Add Service</Title>
      <Card shadow="sm" p="xl" radius="md" withBorder>
        <Container>
          <form
            onSubmit={form.onSubmit(async (formValues) => {


              // Use FormData to retrieve the form values

              console.log("submitting", form.values.action);

              // const values: (Order & UpdateLink) = formValues as (Order & UpdateLink)
              setMsg(null)
              setPending(true);

              let formValuesToBeSent = { ...formValues };  //I'm making a copy because I might modify things here.  I don't want the form to reflect those changes.
              formValuesToBeSent.service = { ...formValues.service };  //same


              try {
                let token = await userContext.getAzureADToken!(userContext);
                // console.log("Token", token);

                if (userContext.user?.providerId === "microsoft.com" && !token) {
                  setIsError(true);
                  console.log("No Microsoft Token");
                  setMsg("No Microsoft Token.  Try refreshing.");
                  return;
                }
                else if (token) {
                  formValuesToBeSent.token = token;
                }

                //generate a doc ID
                const docID = doc(collection(db, "sms")).id;
                setSMSDocID(docID);
                formValuesToBeSent.updateLink.smsDocID = docID;

                formValuesToBeSent.id = customerObj?.id;

                // console.log(values);


                //change to call firebase function and calculate the stuff or something
                if (useWhatsapp) {
                  formValuesToBeSent.updateLink!.useWhatsapp = true;
                }
                else {
                  formValuesToBeSent.updateLink!.useWhatsapp = false;
                }

                const AddService = httpsCallable<AddServiceRequest, any>(functions, "addService");

                //Change the SMS sender ID based on service type

                if (formValuesToBeSent.service.serviceType === "kfhtrade") {
                  formValuesToBeSent.updateLink.senderID = "KFHTrade";
                }
                else if (formValuesToBeSent.service.serviceType === "kfhbrokerage") {
                  formValuesToBeSent.updateLink.senderID = "KFHCapital";
                }
                else if (formValuesToBeSent.service.serviceType === "portfolio") {
                  formValuesToBeSent.updateLink.senderID = "KFHCapital";
                }

                //set serviceType to submit because we want the user to create an entirely new KYC
                const res = await AddService(formValuesToBeSent);
                console.log(res);

                const returnedServiceData = res.data.data;

                // e.target.reset();
                //the href is absolutely stupid.  I'm reutilizing the sendBatchSMS from firebase functions, so yeah.
                setMsg(
                  <Stack>

                    <Text>{form.values.service.serviceType} service created successfully {" "}




                      <Text span >
                        <a href={`/services/${res.data.data?.id}`}>

                          view
                        </a>
                      </Text>
                    </Text>

                  </Stack>
                );
                setIsError(false);
                if (returnedServiceData) {
                  setCreatedService(returnedServiceData as Service);
                  // navigate("/services", {
                  //   state: {
                  //     refreshServicesIn: 1000,
                  //   }
                  // });
                }

                setShowSendOrderButton(false);

              } catch (error: any) {
                setIsError(true);
                console.log(error);
                setMsg(error.message);
              }

              setPending(false);
            })}
          >
            <Grid>
              <Grid.Col span={{ xs: 12 }}>

                <Text
                  variant="gradient"
                  gradient={{
                    from: form.errors?.civilID ? "#fa5252" : "indigo",
                    to: form.errors?.civilID ? "#FF6B6B" : "cyan",
                    deg: 45,
                  }}
                  style={{ fontFamily: 'Greycliff CF, sans-serif' }}
                  //   ta="center"
                  fz="xl"
                  fw={700}
                >
                  Customer Information
                </Text>
              </Grid.Col>

              <Grid.Col span={{ xs: 12, lg: 6 }}>


                {
                  !userClaims["kfh_rm"] ?
                    <CustomerSelectComponent
                      setCustomerData={setCustomerData}
                      customerData={customerData as (Customer & CustomAutocomplete)[]}
                      defaultValue={form.values.service.customerObj?.value}
                      // ref={civilIDRef}

                      textProps={{
                        label: "Civil ID or Commercial Registry",
                        placeholder: "Enter Civil ID or Commercial Registry",
                        disabled: pending
                      }}
                      handleSubmit={(customerIDValue) => {
                        console.log("We set ID to", customerIDValue);
                        setCustomerID(customerIDValue as string)

                      }}
                    /> :
                    <>
                      <TextInput
                        disabled={pending}

                        label="Civil ID"
                        value={form.values.service.customerObj?.value || customerCivilID}
                        onChange={(event) => {
                          setCustomerCivilID(event.currentTarget.value);

                        }}

                        onKeyDown={(e) => {
                          // console.log("ON KEY DOWN!!!", e.key);
                          //was it a backspace?
                          if (e.key === "Backspace") {
                            console.log("backspace");
                            setCustomerID(null);

                          }
                        }}


                      />

                    </>
                }
              </Grid.Col>
              <Grid.Col span={{ xs: 12, lg: 6 }}>

                <TextInput
                  // withAsterisk
                  label="Email"
                  // placeholder="their@email.com"
                  disabled
                  value={form.values?.service.customerObj?.email || ""}

                />
              </Grid.Col>
              <Grid.Col span={{ xs: 12, lg: 6 }}>

                <TextInput
                  // withAsterisk
                  label="Name (English)"
                  // placeholder="Joe"
                  disabled
                  // {...form.getInputProps("customerObj.name_en")}
                  value={form.values?.service.customerObj?.name_en || ""}

                />
              </Grid.Col>
              <Grid.Col span={{ xs: 12, lg: 6 }}>

                <TextInput
                  // withAsterisk
                  label="Name (Arabic)"
                  // placeholder="جو"
                  disabled
                  value={form.values?.service.customerObj?.name_ar || ""}

                // {...form.getInputProps("customerObj.name_ar")}
                />
              </Grid.Col>
              <Grid.Col span={{ xs: 12, lg: 6 }}>
                <TextInput
                  // withAsterisk
                  label="Mobile"
                  // placeholder="xxxxxxxx"
                  disabled
                  value={form.values?.service.customerObj?.mobile || ""}

                // {...form.getInputProps("customerObj.mobile")}
                />
              </Grid.Col >
              <Divider my={30} style={{ width: "100%" }} />



            </Grid>


            {/*  <Divider my={30} style={{ width: "100%" }} /> */}
            <Grid>
              <Grid.Col span={{ xs: 24, lg: 24 }}>

                <Text
                  variant="gradient"
                  gradient={{ from: "indigo", to: "cyan", deg: 45 }}
                  style={{ fontFamily: 'Greycliff CF, sans-serif' }}
                  //   ta="center"
                  fz="xl"
                  fw={700}
                >
                  Service Type
                </Text>
              </Grid.Col>
              <Grid.Col span={{ xs: 6 }}>

                <Select
                  label="Type"
                  // name="type"
                  data={SERVICE_TYPES}
                  {...form.getInputProps("service.serviceType")}
                  onChange={(value: string | null) => {
                    if (value) {

                      form.setFieldValue("service.serviceType", value as ServiceType)
                      form.setFieldValue("service.type", value as ServiceType)
                    }
                  }}

                />
              </Grid.Col>



            </Grid>

            <Divider my={30} style={{ width: "100%" }} />

            <Grid>
              <Grid.Col span={{ xs: 24, lg: 24 }}>

                <Text
                  variant="gradient"
                  gradient={{ from: "indigo", to: "cyan", deg: 45 }}
                  style={{ fontFamily: 'Greycliff CF, sans-serif' }}
                  //   ta="center"
                  fz="xl"
                  fw={700}
                >
                  RM Information
                </Text>
              </Grid.Col>
              <Grid.Col span={{ xs: 12, }}>

                <Grid >

                  <Grid.Col span={{ xs: 4 }} >

                    <Text>
                      {userContext.user?.displayName}
                    </Text>
                  </Grid.Col>

                  <Grid.Col span={{ xs: 6 }} >

                    <Checkbox
                      checked={onBehalfOfChecked}

                      disabled={pending}
                      onChange={(e) => {
                        if (!e.target.checked) {
                          form.setFieldValue("onBehalfOf", undefined);

                        }
                        setOnBehalfOfChecked(e.target.checked)
                      }}

                      label="On behalf of"
                    />

                  </Grid.Col>
                  <Grid.Col span={{ lg: 6 }}>
                    <RmsSelectComponent


                      defaultValue={form.values.service.rm?.rm_id}

                      textProps={{
                        label: "On Behalf of RM:",
                        placeholder: "search RM by name or email",
                        disabled: pending || !onBehalfOfChecked

                      }}
                      handleSubmit={(selectedRM: RMUser) => {
                        form.setFieldValue("onBehalfOf", selectedRM);
                      }}

                    />



                  </Grid.Col>
                </Grid>


              </Grid.Col >



              <Grid.Col span={{ xs: 12, }}>
                <Space h="xl" />
                {msg != null && ( //love my check if it's an error or not.  Please don't do this, it's just a proof of concept page.
                  <Alert
                    icon={<IconAlertCircle size={16} />}
                    color={isError ? "red" : "green"}
                    title={isError ? "Failure" : "Success"}
                  >
                    {msg}
                  </Alert>
                )}
                {form.errors != null && Object.keys(form.errors).length > 0 && (
                  <>
                    <Alert
                      icon={<IconAlertCircle size={16} />}
                      color="red"
                      title="Check form"
                    >
                      {Object.values(form.errors).join(", ")}
                    </Alert>
                    {/* <Text>{JSON.stringify(form.values)}</Text> */}
                  </>
                )}
              </Grid.Col>
              <Space h="xl" />
              {SMSDocID &&
                <Grid.Col span={12} >


                  <MessagesSent docID={SMSDocID} handleFailedMessages={() => {

                    const handleServiceSMS = async () => {

                      const sendServiceSMS = httpsCallable<SendServiceSMSRequest, BaseResult>(functions, "sendServiceSMS");


                      //generate a doc ID
                      const docID = doc(collection(db, "sms")).id;
                      setSMSDocID(docID);

                      //set type to submit because we want the user to create an entirely new KYC
                      await sendServiceSMS({
                        service: createdService!,
                        smsDocID: docID,
                        token: await userContext.getAzureADToken!(userContext)
                      });
                    }
                    handleServiceSMS();
                  }} />




                </Grid.Col>
              }

              {createdService && <Button fullWidth mt={5}
                onClick={() => navigate("/services", {
                  state: {
                    refreshServicesIn: 1000,
                  }
                })}
                variant='outline'
                color={"darkgreen"}
              >
                Back to services
              </Button>}
              {showSendOrderButton && <Grid.Col span={{ xs: 12 }}>

                <Group justify="center" mt="md">

                  <Button type="submit" onClick={() => form.setFieldValue("action", "send_service_sms")} loading={pending}
                  // disabled={isSubmitted}
                  >
                    Send Service {useWhatsapp ? " Whatsapp " : " SMS "}Message
                  </Button>
                  {(userClaims.admin || userClaims.cr) && showSendOrderButton &&
                    <Grid.Col span={3} >

                      <Checkbox
                        checked={useWhatsapp}

                        label={<Text>
                          send through whatsapp
                          <Text span c="blue">
                            {(useWhatsapp ? " (uncheck to use SMS)" : "")}

                          </Text>
                          {useWhatsapp && whastappTemplateLoading && <Text c={"green"}>
                            Loading Template...

                            <span>

                              <Loader size={14} />
                            </span>
                          </Text>}
                        </Text>
                        }
                        onChange={(e) => {
                          setUseWhatsapp(e.target.checked)
                          form.setFieldValue("updateLink.selectedWhatsappTemplate", undefined);
                          form.setFieldValue("updateLink.useWhatsapp", false);
                        }}
                      />
                    </Grid.Col>
                  }

                  {
                    (useWhatsapp && !whastappTemplateLoading && !form.values.updateLink?.selectedWhatsappTemplate) &&
                    <Grid.Col span={12}>
                      <Text c="red">
                        Invalid WhatsApp template, Please use SMS Instead
                      </Text>
                    </Grid.Col>
                  }
                </Group>

              </Grid.Col>}

            </Grid >
          </form >
        </Container >
      </Card >

      {(customerCivilID || toBeUpdatedCustomer?.civilID) && <CustomModal
        title={<Title>{toBeUpdatedCustomer === undefined ? "Add Client" : "Update Client"}</Title>}
        opened={addClientModal}

        // setOpened={setAddClientModal`}
        onClose={async () => {
          setAddClientModal(false);
        }}
        // overlayOpacity={0.22}
        // overlayBlur={3}
        centered
      >
        <RequestKYCUpdate civilID={toBeUpdatedCustomer?.civilID ?? customerCivilID!} customer={toBeUpdatedCustomer} isUpdate={toBeUpdatedCustomer !== undefined}

          onClose={() => {
            if (customerCivilID) {
              getCustomerData(customerCivilID)
            }

          }}
          setModal={setAddClientModal} />
      </CustomModal>}


    </Stack >
  );
}




export default AddService