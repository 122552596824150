import { Alert, Grid, } from '@mantine/core'



import React, { useEffect, useState } from 'react';
import { SecurityRow } from '../../components/security_row';
import { UseFormReturnType } from '@mantine/form';
import { CustomAutocomplete, FirebaseTransaction, Investment, Security, SecurityItem } from '../../types';



export interface SecurityFormProps {

    form: UseFormReturnType<FirebaseTransaction, (values: FirebaseTransaction) => FirebaseTransaction>,
    securitiesList: Security[],
    disabled: boolean,

}


const SecurityForm = ({ form, securitiesList, disabled }: SecurityFormProps) => {

    const [addedSecuritiesList, setAddedSecuritiesList] = useState<SecurityItem[]>([{ quantity: 1 }])

    const [displayedSecuritiesList, setDisplayedSecuritiesList] = useState<(Security & CustomAutocomplete)[] | (Investment & CustomAutocomplete)[]>([])


    useEffect(() => {

        if (disabled) {
            setDisplayedSecuritiesList(form.values.securities.map((security) => {
                return security.value as any;
            }));
        }

        else {


            if (form.values.transactionType === "security_out") {

                let securitiesRows: Investment[] = form.values.position?.filter((position: Investment) => position._INVESTMENT_INVESTMENTTYPE_CODE_ === "CS" && form.values.custodianAccount?.id === position.CUSTODIANACCOUNT).map((position: Investment) => {
                    let data: Investment & CustomAutocomplete = {
                        ...position, label: position.TICKER + " - " + position.EXCHANGE_DESCRIPTION + " - (" + position.QTYEND + ")",
                        value: position.CODE,
                    };
                    data["id"] = position.CODE;
                    data["text_r"] = position.TICKER + " - " + position.EXCHANGE_DESCRIPTION + " - (" + position.QTYEND + ")";
                    data["text_l"] = position.CODE;
                    data["group"] = data.EXCHANGE_DESCRIPTION;
                    data["key"] = position._KEY_;
                    // data["value"] = position.CODE;

                    return data;
                }) || [];

                setDisplayedSecuritiesList(securitiesRows as any);  //TODO: remove any and fix this later

            }
            else {
                setDisplayedSecuritiesList(securitiesList as any);
            }

        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form.values.position, form.values.custodianAccount?.id])

    // const handleQuantityComapny = (value: any, idx: number) => {

    //     const currentCompanies = [...addedSecuritiesList];
    //     const chosenCompanyPrice = securitiesList.filter((company: any) => company.value == currentCompanies[idx].value)[0].price
    //     currentCompanies[idx] = { ...currentCompanies[idx], quantity: value, price: chosenCompanyPrice * value };
    //     setAddedSecuritiesList(currentCompanies)
    //     form.setFieldValue("securities", currentCompanies);

    // }

    if (!displayedSecuritiesList.length)
        if (!form.values.customerID)
            return <Grid.Col span={{ xs: 12 }} >

                <Alert
                    style={{ width: "100%" }}
                >
                    Please Select a portfolio
                </Alert>
            </Grid.Col>

        else return <Grid.Col span={{ xs: 12 }} >
            <Alert
                style={{ width: "100%" }}
            >
                No securities found
            </Alert>
        </Grid.Col >
    return (

        <>
            {/* <Grid.Col span={{ xs: 12 }}>

                <Radio.Group
                    label="Action"
                    required
                    description="action of Security flow update"

                    {...form.getInputProps('action')}
                >
                    <Radio mt="xs" value="ReceiveLong" label="Security In" />
                    <Radio mt="xs" value="DeliverLong" label="Security Out" />

                </Radio.Group>
            </Grid.Col> */}

            {addedSecuritiesList?.map((security: any, idx: number) => {

                return <SecurityRow
                    disabled={disabled}
                    securitiesList={displayedSecuritiesList}
                    addedSecuritiesList={addedSecuritiesList}
                    setAddedSecuritiesList={setAddedSecuritiesList}
                    form={form}
                    idx={idx}
                    key={idx}
                />
            })



            }

        </>


    )
}

export default SecurityForm