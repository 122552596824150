import React, { useContext, useMemo, useState } from "react";

import { useNavigate } from "react-router-dom";
import { Group, Space, Title, Card, Grid, Tooltip, Badge, ThemeIcon } from "@mantine/core";
import { BsPlusCircle } from "react-icons/bs";
import { Button } from "@mantine/core";
import SendSMSModal from "../../components/send_sms_modal";
import CustomModal from "../../components/custom_modal";
import RequestKYCUpdate from "../requestKYCUpdate";
import GeneratePDFModal from "../../components/generate_pdf_modal";

import { IconEye, } from "@tabler/icons-react";
import CustomerInstantSearch from "../../components/customer_instant_search";
import { Action, ActionType, BaseResult, CustomClaims, Customer, } from "../../types";
import { Timestamp, collection, getDocs, orderBy, query } from "firebase/firestore";
import CustomListActionsModal from "../../components/custom_actions_modal";
import { httpsCallable } from "firebase/functions";
import { useFirestore, useFunctions } from "../../helpers/firebaseContext";
import { MyUserDataContext } from "../../contexts";
import GenerateConfirmedOrdersCSVModal from "../../components/generate_confirmed_orders_csv_modal";


interface ActionsComponentProps {
  customerObj: Customer;
}
const CustomersListTypesense = () => {


  const navigate = useNavigate();
  const functions = useFunctions();
  const db = useFirestore();

  const [SMSOpened, setSMSOpened] = useState(false);
  const [generatePDFOpen, setGeneratePDFOpen] = useState(false);
  const [kycStatusUpdateOpen, setKycStatusUpdateOpen] = useState(false);
  const [addClientModal, setAddClientModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [generateCSVOpen, setGenerateCSVOpen] = useState(false);

  const kycAction = httpsCallable<Action, BaseResult>(functions, "kycAction");



  const RenderActions = ({ customerObj }: ActionsComponentProps) => {

    // console.log("customerObj ====>>>", customerObj);
    //if it came from typesense search, then the object is going to be incorrect and date won't be in Timestamp format
    if (customerObj.lastUnsubmittedKYCMessage?.date && !(customerObj.lastUnsubmittedKYCMessage.date instanceof Timestamp)) {
      customerObj.lastUnsubmittedKYCMessage.date = new Timestamp(customerObj.lastUnsubmittedKYCMessage.date as any, 0);
    }





    return <Group>
      {/*   TODO: Yousef: Disabling this for now until we can implement it properly
        <Menu
          position="bottom-start"
          withArrow
          arrowPosition="center"
        >
          <Menu.Target>
  
            <UnstyledButton pr={12}> <IconDots />
  
  
            </UnstyledButton>
  
          </Menu.Target>
          <Menu.Dropdown>
  
            <Menu.Item
              leftSection={<IconDeviceMobileMessage size={16} color={theme.colors.blue[6]} stroke={1.5} />}
              onClick={async () => {
                return;
              }
              }
            >
              SMS
            </Menu.Item>
            <Menu.Item
              leftSection={<IconCashBanknote size={16} color={theme.colors.blue[6]} stroke={1.5} />}
              onClick={() => {
                navigate(`/transactions/add/`, { state: { type: "cash_in", customer: customerObj.id } });
              }}
            >
  
              Cash in
            </Menu.Item>
            <Menu.Item
              leftSection={<IconCashBanknote size={16} color={theme.colors.blue[6]} stroke={1.5} />}
              onClick={() => {
                navigate(`/transactions/add/`, { state: { type: "cash_out", customer: customerObj } });
              }}
            >
              Cash out
            </Menu.Item>
            <Menu.Item
              leftSection={<IconCertificate size={16} color={theme.colors.blue[6]} stroke={1.5} />}
              onClick={() => {
                navigate(`/transactions/add/`, { state: { type: "security_in", customer: customerObj.id } });
              }}
            >
              Security In
            </Menu.Item>
            <Menu.Item
              leftSection={
                <IconCertificate2 size={16} color={theme.colors.blue[6]} stroke={1.5} />
              }
              onClick={() => {
                navigate(`/transactions/add/`, { state: { type: "security_out", customer: customerObj.id } });
              }}
            >
              Security Out
            </Menu.Item>
            <Menu.Item
              leftSection={
                <IconCertificate2 size={16} color={theme.colors.blue[6]} stroke={1.5} />
              }
              onClick={() => {
                navigate(`/transactions/add/`, { state: { type: "fx", customer: customerObj.id } });
              }}
            >
              FX + Transfer
            </Menu.Item>
            <Menu.Item
              leftSection={
                <IconShoppingCartPlus size={16} color={theme.colors.blue[6]} stroke={1.5} />
              }
  
              onClick={() => {
                navigate(`/add_order/${customerObj.civilID}`);
              }}
            >
              Add Order
            </Menu.Item>
  
  
          </Menu.Dropdown>
  
        </Menu> */}

      <ThemeIcon color="blue" variant="light" onClick={() => {
        navigate("/customers/" + customerObj.id, { state: customerObj })
      }}><IconEye size={16} /></ThemeIcon>

      {customerObj.lastUnsubmittedKYCMessage?.count ? <>
        <Tooltip
          w={220}
          multiline
          label={`Number of KYC requests with no response: (${customerObj.lastUnsubmittedKYCMessage?.count}),
                          Last request sent(${customerObj.lastUnsubmittedKYCMessage.date.toDate().toLocaleString("en-GB", { hour12: true })}) `} >

          <Badge size="xs" color="red">{customerObj.lastUnsubmittedKYCMessage?.count}</Badge>
        </Tooltip>
      </> : null}

    </Group>

  }
  const userData = useContext(MyUserDataContext);
  let customClaims = userData.firebaseTokenResult?.claims.customClaims as CustomClaims;

  //don't show top bar for kfh_rm and kfh_oper
  const topElements = !customClaims.kfh_rm && !customClaims.kfh_oper ? (<Group>
    <Button
      color="grape"
      disabled={selectedRows.length === 0}
      onClick={() => setSMSOpened(true)}
    >
      Send SMS to {selectedRows.length} customers
    </Button>

    {/* <Button c="orange" loading={approvingAll} onClick={() => {setApprovingAll(true); console.log("ey");}}>Approve all</Button> */}
    <Button
      color="yellow"
      disabled={selectedRows.length === 0}
      onClick={() => setGeneratePDFOpen(true)}
    >
      {`Generate KYC for ${selectedRows.length} customers`}
    </Button>
    <Button
      color="lime"
      disabled={selectedRows.length === 0}
      onClick={() => setKycStatusUpdateOpen(true)}
    >
      {`Change KYC status for ${selectedRows.length} customer(s)`}
    </Button>
    {customClaims.admin && <Button
      color="pink"
      // disabled={selectedRows.length === 0}
      onClick={() => setGenerateCSVOpen(true)}
    >
      {`Completed Orders CSV`}
    </Button>}

  </Group>) : undefined;


  const searchableCustomers = useMemo(() => <CustomerInstantSearch
    setSelectedRows={setSelectedRows}
    selectable={true}
    selectedRows={selectedRows}
    renderTags={true}
    navigateDetails={(obj: any) =>
      navigate("/customers/" + obj.id, { state: obj })
    }
    ActionsComponent={RenderActions}
    indexName={process.env.REACT_APP_TYPESENSE_CUSTOMERS_COLLECTION!}
    topElements={topElements}
  />,
    // eslint-disable-next-line 
    [selectedRows]);

  const customerModals = useMemo(() => <>
    <SendSMSModal
      rows={selectedRows}
      opened={SMSOpened}
      setOpened={setSMSOpened}
    />
    <GeneratePDFModal
      rows={selectedRows}
      opened={generatePDFOpen}
      setOpened={setGeneratePDFOpen}
      includeKYC={true}
    />
    <CustomModal
      title={<Title>Add Client</Title>}
      opened={addClientModal}
      // setOpened={setAddClientModal`}
      onClose={() => setAddClientModal(false)}
      // overlayOpacity={0.22}
      // overlayBlur={3}
      centered
    >
      <RequestKYCUpdate setModal={setAddClientModal} />
    </CustomModal>
    <CustomListActionsModal<Customer>
      rows={selectedRows}

      opened={kycStatusUpdateOpen}
      setOpened={setKycStatusUpdateOpen}
      availableActions={[
        {
          value: "review",
          label: "Review"
        },
        {
          value: "approve",
          label: "Approve"
        },

      ]
      }
      handleSubmit={
        async (customerKyc: Customer, action: string, token?: string) => {
          const kycCollection = collection(db, "customers", customerKyc.id!, "kyc");
          const kycQuery = query(kycCollection, orderBy("created", "desc"),);
          const kycsList = await getDocs(kycQuery);
          const kycID = kycsList.docs[0]
          await kycAction({
            action: (action as ActionType)!,
            kycID: kycID.id || "",
            customerID: customerKyc.id,
            token: token!,
          });

        }


      }
      renderErrors={(failedItem: any) =>
        <>
          {failedItem.name_en}

          <Space h="xl" />

        </>
      }
      getItemsData={

        (action: string, rows: Customer[]) => {


          let data: Customer[] = []


          if (action === "review")
            data = rows.filter((kyc: Customer) => {
              return kyc?.tags?.kycStatus.code === "review" || kyc?.tags?.kycStatus.code === "client_update"
            })
          if (action === "approve") {
            data = rows.filter((kyc: Customer) => {
              console.log("kyc?.tags?.kycStatus.code  =======>>>", kyc?.tags?.kycStatus.code);
              return kyc?.tags?.kycStatus.code === "approval" || kyc?.tags?.kycStatus.code === "exec_approval"
            }
            )

          }
          if (action === "operations_approve")
            data = rows.filter((kyc: Customer) => kyc?.tags?.kycStatus.code === "processing")

          return data

        }
      }
    />
    <GenerateConfirmedOrdersCSVModal
      opened={generateCSVOpen}
      setOpened={setGenerateCSVOpen}
    />

  </>,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedRows, SMSOpened, addClientModal, generatePDFOpen, kycStatusUpdateOpen, generateCSVOpen])

  return (
    <>
      <Group justify="space-between">
        <Title>Clients</Title>
        <Button
          leftSection={<BsPlusCircle />}
          onClick={() => setAddClientModal(true)}
        >
          Add client
        </Button>
      </Group>
      <Space h="xl" />
      {customerModals}

      <Card shadow="sm" p="lg" radius="md" withBorder>
        <Grid justify="space-between">
          <Grid.Col>

          </Grid.Col>
          <Grid.Col>

            {searchableCustomers}
          </Grid.Col>
        </Grid>

      </Card>
    </>
  );
};

export default CustomersListTypesense
