import React, { useEffect, useState } from 'react'
import CustomDataTable from '../../components/custom_data_table'
import { collection, query, onSnapshot, where, QueryDocumentSnapshot } from 'firebase/firestore';
import { FundAutoComplete, FundID } from '../../types';
import { useFirestore } from '../../helpers/firebaseContext';
import CreateFundCampaignButton from './create_campaign_button';
import { ActionIcon, CopyButton, Space, Tooltip } from '@mantine/core';
import { Button } from '@mantine/core';
import { Title } from '@mantine/core';
import { Group } from '@mantine/core';
import { BsPlusCircle } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { IconCheck, IconCopy } from '@tabler/icons-react';

const FundReportsList = () => {

    const TABLE_KEY = "fund_reports_factsheets";
    const db = useFirestore();
    const [funds, setFunds] = useState([] as FundAutoComplete[]);
    const navigate = useNavigate();

    //get funds
    useEffect(() => {

        const fundCollection = collection(db, "funds");
        const q = query(fundCollection,
            where("kfhc_website_id", ">=", ""),
            // where("private", "==", true)
        );

        return onSnapshot(q, (snapshot) => {
            if (snapshot.metadata.fromCache) {
                console.log("Cached data", snapshot.docs.length);

            } else {
                console.log("Server data", snapshot.docs.length);
            }


            let fundArray = [] as FundAutoComplete[];

            snapshot.docs.forEach((doc) => {
                var data = doc.data() as FundAutoComplete;
                //TODO: for now, only return funds with ibans available (open ended).  Later, we need to have a flag for the type of fund
                // if (!data.iban) return;
                data["id"] = doc?.id as FundID;
                data["value"] = doc?.id;
                data["label"] = `${data?.name_en} - ${data?.name_ar} `

                fundArray.push(data);
                // return data;
            });
            // console.log("funds", rows);
            setFunds(fundArray);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns: any[] = [
        {
            name: "Document Title",
            cell: (row: QueryDocumentSnapshot<any>) => {

                return row.data().TITLE
            },
        },
        {
            name: "Fund Name",
            cell: (row: QueryDocumentSnapshot<any>) => {

                return row.data().FUND_NAME_EN
            },
        },

        {
            name: "Added On",
            cell: (row: QueryDocumentSnapshot<any>) => {
                return (`${row.data()?.DATE?.toDate()?.toLocaleDateString("en-GB")}`)
            },
        },
        {
            name: "PDF",
            cell: (row: QueryDocumentSnapshot<any>) =>
                <>

                    <CopyButton value={`https://www.kfhcapital.com.kw/media/${row.data()?.PDF?.split("MediaArchive:").pop()}`} timeout={2000} >
                        {({ copied, copy }) => (
                            <Tooltip label={copied ? 'Copied' : 'Copy'} withArrow position="right">
                                <ActionIcon color={copied ? 'teal' : 'gray'} variant="subtle" onClick={copy}>

                                    {copied ? <IconCheck size={16} /> : <IconCopy size={16} />}
                                </ActionIcon>
                            </Tooltip>
                        )}
                    </CopyButton >
                </>

        },
        {
            name: "",
            cell: (row: QueryDocumentSnapshot<any>) => {
                const chosenFund = funds?.find((fund) => {

                    return fund.id === row.data().FUND_ID
                })
                return <CreateFundCampaignButton

                    variables={{
                        mediaID: row.data()?.PDF?.split("MediaArchive:").pop(),
                        fundName: chosenFund?.label,
                        date: `${row.data()?.DATE?.toDate()?.toLocaleDateString("en-GB")}`
                    }}
                    fund={chosenFund} />
            },
        },

    ]

    const renderTable = React.useMemo(() => {
        return <CustomDataTable
            orderByAttribute="DATE"
            orderDirection="desc"
            tableKey={TABLE_KEY}
            // title="Campaigns"
            isCollectionGroup={true}
            columns={columns}
            // customFilters={customFilters}
            // customQueries={customQueries}



            paginationComponentOptions={{
                selectAllRowsItem: true,
                selectAllRowsItemText: "All",
            }}
            // filterAttributes={'TITLE'}
            selectableRows={false}
            selectableRowsVisibleOnly={false}
            selectableRowsHighlight={false}

        />;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        funds
    ]);


    return (
        <div>

            <Group justify="space-between">
                <Title>Fund Factsheets</Title>
                <Button
                    leftSection={<BsPlusCircle />}
                    onClick={() => {
                        navigate("/funds-reports/create");
                    }}
                >
                    {" "}
                    Add Report/Factsheet{" "}
                </Button>
            </Group>

            <Space h="xl" />
            {renderTable}

        </div>
    )
}

export default FundReportsList