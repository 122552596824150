import { Alert, Box, Button, Modal, PasswordInput, Popover, Progress, Text } from '@mantine/core'
import { useForm } from '@mantine/form'
import React, { useState } from 'react'
import { AddKFHRmRequest, BaseResult, RMUser } from '../../types'
import { httpsCallable } from 'firebase/functions'
import { IconAlertCircle, IconCheck, IconX } from '@tabler/icons-react'
import PasswordStrengthComponent, { passwordRequirements } from './PasswordStrengthComponent'
import { useFunctions } from '../../helpers/firebaseContext'

interface UpdateRMPasswordModalType {
    open: boolean
    onClose: () => void
    user: RMUser,
    handleSubmit?: () => void
}
interface UpdatePasswordForm {

    password: string,
    confirmPassword: string,
    email: string


}





const UpdateRMPasswordModal = ({ open, onClose, user, handleSubmit }: UpdateRMPasswordModalType) => {



    const [changingPassword, setChangingPassword] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string | null>(null)

    const functions = useFunctions();

    const form = useForm<{
        password: string,
        confirmPassword: string,
        email: string

    }>({
        initialValues: {

            password: "",
            confirmPassword: "",
            email: user.email

        },

        validate: {

            password: (val: string) => {
                let err: string | null = null
                if (val.length <= 7)
                    err = 'Password should include at least 8 characters'


                passwordRequirements.forEach((requirement: any) => {
                    if (!requirement.re.test(val)) {
                        err = requirement.label as (string)
                    }
                });
                return err



            }

            ,
            confirmPassword: (value, values) =>
                value !== values.password ? 'Passwords did not match' : null,

        },
    });



    return (


        <Modal
            opened={open}
            onClose={onClose}
            title={`Change Password form - ${user.email}`}
        >

            <form
                onSubmit={form.onSubmit(async (formValues) => {

                    try {
                        setChangingPassword(true)
                        const updateRM = httpsCallable<UpdatePasswordForm, BaseResult>(functions, "updateRM");
                        const res = await updateRM(formValues);
                        if (res.data.status === "success") {
                            onClose()
                        }
                        else {
                            setErrorMessage(res.data.message || "That didn't work.");
                        }
                        if (handleSubmit) {
                            handleSubmit();
                        }

                    }
                    catch (e: any) {

                        console.log("AN ERR HAS OCCURED ==========>>>", e);
                        setErrorMessage(e.message)

                    }
                    setChangingPassword(false)
                })}
            >
                <PasswordStrengthComponent passwordValue={form.values.password} passwordProps={form.getInputProps("password")} />

                <PasswordInput
                    {...form.getInputProps("confirmPassword")}

                    required
                    label="Confirm Password" />

                <Button loading={changingPassword} mt={"xl"} type="submit">
                    Update Password
                </Button>

                {errorMessage && <Alert variant="light" color="red" title="Alert" icon={<IconAlertCircle />}>

                    {errorMessage}
                </Alert>}

            </form>

        </Modal>


    )
}

export default UpdateRMPasswordModal