

import { Badge, Button, Checkbox, Grid, Group, Menu, Space, Text, UnstyledButton } from '@mantine/core';
import { HitsPerPage, InstantSearch, Pagination, SearchBox, useHits, InstantSearchProps, useInstantSearch, Configure, useSortBy, } from 'react-instantsearch';

import TypesenseInstantSearchAdapter, { SearchClient } from "typesense-instantsearch-adapter";
import { forwardRef, useContext, useImperativeHandle, useState } from 'react';
import { MyUserDataContext } from '../contexts';
import DataTable, { TableColumn } from 'react-data-table-component';

import { useSessionStorage } from '@mantine/hooks';
import { KYCStatusCode, RMUser } from '../types';
import TypesenseInstantsearchAdapter from 'typesense-instantsearch-adapter';
import { HighlightedText } from '../helpers/common';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { IconCircleCheck, IconCircleX, IconSettings } from '@tabler/icons-react';


//typesense

interface InitialState {
    query?: string;
    page?: number;
}

// Define the interface for the ref
export interface RefreshButtonRef {
    refresh: () => void;
}


export const RefreshButton = forwardRef<RefreshButtonRef>((props, ref) => {
    const { refresh } = useInstantSearch();

    useImperativeHandle(ref, () => ({
        refresh
    }));

    return (
        <Button onClick={() => refresh()}>
            Refresh
        </Button>
    );
});




const TableDisplay = ({ indexName, setSelectedRows, selectedRows, renderTags, ActionsComponent, navigateDetails, queryValue, onTotalRMsUpdate, ...props }: any) => {
    const { items, results } = useHits();

    onTotalRMsUpdate(results?.nbHits);

    const { refine, } = useSortBy(
        {
            items: [
                { label: 'Created (asc)', value: `${indexName}/sort/created:asc` },
                { label: 'Created (desc)', value: `${indexName}/sort/created:desc` },
            ]


        }
    );

    const handleChange = ({ selectedRows }: any) => {
        // You can set state or dispatch with something like Redux so we can use the retrieved data
        if (setSelectedRows) {
            setSelectedRows(selectedRows);
        }
    };
    const handleStatusUpdate = (row: RMUser) => {
        props?.setAddRmModal(true)
        props?.setRmUser(row)
    }
    const handleChangePassword = (row: RMUser) => {
        props?.setUpdateRmModal(true)
        props?.setRmUser(row)
    }
    const columns: TableColumn<any>[] = [
        {
            name: 'Name',
            id: "name_en",
            sortable: true,
            selector: (row: any) => row.name_en,
            grow: 2,
            cell(row,) {
                const displayedLabel = `${row.name_en}`
                return <HighlightedText displayedLabel={displayedLabel} queryValue={queryValue || ""} />

            },
            // grow: true,
        },
        {
            name: 'Email',
            id: "email",
            selector: (row: any) => row.email,
            sortable: true,
            grow: 2,
            cell(row,) {
                const displayedLabel = `${row.email}`
                return <HighlightedText displayedLabel={displayedLabel} queryValue={queryValue || ""} />
            },
        },
        {
            name: 'Mobile',
            id: "mobile",
            selector: (row: any) => row.mobile,
            sortable: true,
            grow: 2,
            cell(row,) {
                const displayedLabel = `${row.mobile}`
                return <HighlightedText displayedLabel={displayedLabel} queryValue={queryValue || ""} />
            },
        },
        {
            name: 'ID',
            id: "rm_id",
            selector: (row: any) => row.rm_id,
            sortable: true,
            grow: 0.5,
            cell(row,) {
                const displayedLabel = `${row.rm_id}`
                return <HighlightedText displayedLabel={displayedLabel} queryValue={queryValue || ""} />
            },
        },
        {
            name: 'Segment',
            grow: 1,
            // Start of Selection
            cell: (row: any) => {

                switch (row.segment) {
                    case "Private Banking":
                        return <Badge color="#c5a548" size="sm">{row.segment}</Badge>
                    case "Individual Finance":
                        return <Badge color="orange" size="sm">{row.segment}</Badge>
                    case "Ruwad":
                        return <Badge color="royalblue" size="sm">{row.segment}</Badge>
                    case "Tamayoz":
                        return <Badge color="maroon" size="sm">{row.segment}</Badge>
                    case "KFH Capital":
                        return <Badge color="forestgreen" size="sm">{row.segment}</Badge>

                    default:
                        return <span></span>

                }
            }
        },
        {
            name: "Active",
            cell: (row: RMUser) => row?.active ? <IconCircleCheck color="green" /> : <IconCircleX color="red" />
            ,
            sortable: true,
            sortField: "active"
        },

        {
            name: "Update",
            cell: (row: RMUser) =>
                <>
                    <Menu shadow="md" width={200}>
                        <Menu.Target>
                            <UnstyledButton variant="">

                                <BsThreeDotsVertical size={16} />
                            </UnstyledButton>
                        </Menu.Target>

                        <Menu.Dropdown>
                            {/* <Menu.Label>Application</Menu.Label> */}
                            <Menu.Item leftSection={<IconSettings />} onClick={() => handleStatusUpdate(row)}>
                                Update User
                            </Menu.Item>
                            <Menu.Item leftSection={<IconSettings />} onClick={() => handleChangePassword(row)}>
                                Change Password
                            </Menu.Item>


                        </Menu.Dropdown>
                    </Menu>


                </>

            ,
            sortable: false,
        },

    ];


    return <>
        <DataTable
            sortServer
            onSort={(column, sortDirection) => {
                if (column.id) {
                    let sortByValue = `${indexName}/sort/${column.id}`
                    if (sortDirection === "asc") {
                        sortByValue += `:asc`
                    }
                    else {
                        sortByValue += `:desc`
                    }

                    refine(sortByValue)
                }
            }}
            columns={columns}

            selectableRows
            onSelectedRowsChange={handleChange}
            data={items.map((hitObj) => {
                return { ...hitObj, customerID: hitObj.id }
            })}
        />
    </>

}

interface RmsInstantSearchProps {
    indexName: string,
    setSelectedRows?: any
    setUpdateRmModal?: any
    setAddRmModal?: any
    setRmUser?: any
    renderTags?: boolean
    ActionsComponent?: any
    navigateDetails?: any
    selectable?: boolean
    selectedRows?: any[]
    topElements?: JSX.Element,
    showTopElements?: boolean
    additionalChildren?: JSX.Element

}
const RmsInstantSearch = ({
    indexName, selectable, setSelectedRows,
    selectedRows = [], navigateDetails, renderTags,
    ActionsComponent, topElements, setAddRmModal,
    setRmUser, setUpdateRmModal, additionalChildren
}: RmsInstantSearchProps) => {
    const [totalRMs, setTotalRMs] = useState<number | undefined>()

    // Callback function to update total customers
    const handleTotalRMsUpdate = (count: number) => {
        setTotalRMs(count);
    };

    const userData = useContext(MyUserDataContext);

    const typesenseInstantsearchAdapter: TypesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
        server: {
            apiKey: userData.customerCollectionSearchKey!, // Be sure to use a Search API Key
            nodes: [
                {
                    host: process.env.REACT_APP_TYPESENSE_SEARCH_HOST!,
                    port: 443,
                    protocol: 'https'
                },
            ],

        },

        additionalSearchParameters: {

            query_by: "name_en,name_ar,rm_id,email,segment,mobile",

        }
        ,


    })

    let typesenseSearchClient: SearchClient = typesenseInstantsearchAdapter.searchClient as SearchClient;

    if (!indexName) {
        indexName = process.env.REACT_APP_TYPESENSE_RMS_COLLECTION!;
    }


    //save to local session
    const [initialUIStateForIndex, setInitialUIStateForIndex] = useSessionStorage<InitialState>({
        key: 'initialUIStateFor' + indexName,
        getInitialValueInEffect: false,
    });


    const onStateChange: InstantSearchProps['onStateChange'] = ({
        uiState,

    }) => {

        setInitialUIStateForIndex(uiState[indexName]);

    };

    return (
        <InstantSearch

            searchClient={typesenseSearchClient}
            indexName={indexName}
            initialUiState={{
                [indexName]: initialUIStateForIndex
            }}
            onStateChange={onStateChange}

        >
            <Configure


                exactOnSingleWordQuery='attribute'
            // query={"q=*&filter_by=lastUnsubmittedKYCMessage.count:>=4"}

            />




            <Grid justify='center'
                mb={10}
                style={{
                    // background: "red"
                }}>
                <Grid.Col span={12} style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "100%",

                }}>
                    {topElements}
                </Grid.Col>

                <Grid.Col span={9}>

                    <SearchBox

                        style={{
                            display: "flex",
                            justifyContent: "flex-end",

                            width: "100%",
                        }}
                        autoFocus
                        placeholder='Search...'


                    />
                </Grid.Col>
                <Grid.Col span={3}>
                    <Group justify="space-between" >

                        {additionalChildren}

                    </Group>
                </Grid.Col>
                {/* <Grid.Col span={1} style={{
                    // background: "green"
                }}>
                    <Group justify="start">

                        <RefreshButton />
                    </Group>
                </Grid.Col> */}

            </Grid>
            {/* <Text>Index: {process.env.REACT_APP_TYPESENSE_CUSTOMERS_COLLECTION}</Text> */}


            <TableDisplay
                indexName={indexName}
                queryValue={initialUIStateForIndex?.query || ""}
                selectedRows={selectedRows}
                setSelectedRows={selectable ? setSelectedRows : undefined}
                navigateDetails={navigateDetails}
                ActionsComponent={ActionsComponent}
                renderTags={renderTags}
                setAddRmModal={setAddRmModal}
                setUpdateRmModal={setUpdateRmModal}
                setRmUser={setRmUser}
                onTotalRMsUpdate={handleTotalRMsUpdate}

            />

            <div
                style={{
                    display: "flex",
                    justifyContent: "flex-end",

                    width: "100%",
                }}
            >
                <HitsPerPage
                    items={[
                        { label: '250', value: 250, },
                        { label: '100', value: 100 },
                        { label: '50', value: 50, default: true },
                    ]}
                />
            </div>
            <Space h="xl" />
            <Pagination
                style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "100%",


                }}
            />
            {totalRMs && <Text mt={10} ta={'end'} style={{
                'color': '#23263b',
                'font-size': '0.875rem',
                'fontWeight': '400'
            }}>Total Number of RMs: {totalRMs}</Text>}

        </InstantSearch >



    )
}

export default RmsInstantSearch