import { ComboboxProps, Text, TextInputProps } from '@mantine/core';
import { useState, useEffect, useRef, useMemo, Ref, forwardRef, useImperativeHandle, Key } from 'react';
import { Combobox, TextInput, useCombobox } from '@mantine/core';
import { CustomAutocomplete } from '../types';
import { QuerySnapshot, limit, onSnapshot, query, where } from 'firebase/firestore';
import _ from 'lodash';


export interface AutoCompleteComponentProps extends ComboboxProps {
    data?: (any & CustomAutocomplete)[],
    defaultItem?: (any & CustomAutocomplete),
    textProps?: TextInputProps,
    customFilter?: (text: string, item: any) => boolean,
    filterBy?: string,
    handleSubmit?: (item: any) => void,
    queryStatement?: any
    text_r?: string
    text_l?: string
    value: string
    isGrouped?: boolean

}

export interface AutocompleteComponentHandle {
    submitItem: (item: any) => void;
}
export const AutocompleteComponent = forwardRef<AutocompleteComponentHandle, AutoCompleteComponentProps>(
    ({ data, textProps, isGrouped, defaultItem, customFilter, handleSubmit, queryStatement, text_l, text_r, value, filterBy, ...props }: AutoCompleteComponentProps, ref: Ref<AutocompleteComponentHandle>) => {

        // export function AutocompleteComponent<T>({ data, textProps, customFilter, ...props }: AutoCompleteComponentProps<T>) {
        const [text, setText] = useState("");
        const [selectedItem, setSelectedItem] = useState<CustomAutocomplete | undefined | null>(defaultItem);


        const [completedText, setCompletedText] = useState<string | null>(null);

        const [options, setOptions] = useState<(any & CustomAutocomplete)[]>([]);
        const [groupedOptions, setGroupedOptions] = useState<(any & CustomAutocomplete)[]>([]);



        let textChanged = useRef(false);


        const inputRef = useRef<HTMLInputElement>(null);

        const [dataMap, setDataMap] = useState<
            Record<string, any & CustomAutocomplete>>({});

        // const optionsString = JSON.stringify(options);

        useEffect(() => {
            if (isGrouped) {

                const grouped: Record<string, (any & CustomAutocomplete)[]> = _.groupBy(options, (value: any) => value.group);
                console.log("Groupoed Data ======>>", grouped);

                setGroupedOptions(grouped as any)
            }

            console.log("<><><><><><><><><>Options is", options);
        }, [isGrouped, options])



        useImperativeHandle(ref, () => ({

            submitItem(item: any) {
                setSelectedItem(item)
            }

        }));


        useMemo(() => {

            if (data) {
                let map: Record<string, any & CustomAutocomplete> = {};
                data.forEach((item) => {
                    map[item.value] = item;
                });
                setDataMap(map);
            }
        }, [data]);




        useEffect(() => {
            if (completedText) {
                console.log("Completed Text =======>>>", completedText);

                inputRef.current?.setSelectionRange(text.length, completedText.length);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [completedText])


        useEffect(() => {

            const fetchData = async () => {

                console.log(" fetchData ========>", text);

                let q = queryStatement
                let rows = [];
                if (filterBy) {

                    q = query(q,
                        where(filterBy, ">=", text)
                    )

                }
                //we are returning here, because onSnapshot stays active even after the component is unmounted, and it causes a memory leak
                //we need to return the unsubscribe function so that it can be called when the component is unmounted
                const unsubscribe = onSnapshot(
                    query(q, limit(7))
                    ,
                    (snapshot: QuerySnapshot<any>) => {
                        rows = snapshot.docs
                        if (snapshot.empty) {
                            setOptions([]);

                            return;
                        }
                        textChanged.current = false;

                        rows = snapshot.docs.map((doc) => {
                            var data = doc.data() as any;
                            data["id"] = doc.id;
                            data["key"] = doc.id;
                            data["text_l"] = data[text_l as string];
                            data["text_r"] = data[text_r as string];
                            data["value"] = data[value];
                            data["label"] = `${data["text_l"]} - ${data["text_r"]}`;
                            // data["item"] = data;

                            //set expiry date and stuff if low/high risk

                            return data;
                        });
                        console.log("ROWS =====>>", rows);
                        if (rows.length === 1) {

                            combobox.selectFirstOption();
                            combobox.clickSelectedOption();
                            setCompletedText(rows[0].value);
                        }
                        else if (text.length > 2) {
                            combobox.openDropdown();
                        }
                        setOptions(rows);

                    },
                    (error) => {
                        console.log("error =====>>>", error);
                    }
                );


                return () => unsubscribe();
            }
            if (queryStatement && text && textChanged.current) {
                console.log(" queryStatement ========>", text);


                fetchData()
            }
            else if (data && text && textChanged.current) {

                textChanged.current = false;
                let filteredOptions: (any & CustomAutocomplete)[] = [];

                if (customFilter) {
                    filteredOptions = data.filter((item) => customFilter(text, item)).slice(0, 10);
                }
                else {
                    filteredOptions = data.filter((item) => item.label?.toLowerCase().startsWith(text.toLowerCase().trim())).slice(0, 10);
                }

                setOptions(filteredOptions);

                console.log("text", text, "filteredOptions", filteredOptions);
                if (filteredOptions.length === 1) {
                    combobox.selectFirstOption();
                    combobox.clickSelectedOption();

                    setCompletedText(filteredOptions[0].value);

                }
                else if (text.length > 2) {
                    combobox.openDropdown();
                }
            }
            else if (data) {
                setOptions(data);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [text, data])






        const combobox = useCombobox({
            onDropdownClose: () => {
                // combobox.resetSelectedOption();
                // combobox.focusTarget();
            },

            onDropdownOpen: () => {
                // combobox.focusSearchInput();
            },
        });

        return (


            <Combobox
                // width={300}
                position="bottom-start"
                withArrow
                {...props}

                store={combobox}
                withinPortal={true}
                onOptionSubmit={(val, optionProps) => {
                    console.log("Selecting", val, optionProps)
                    const item = dataMap[val];
                    setSelectedItem(item);
                    // setText(item.value);
                    handleSubmit?.(item);


                    //TODO: add callback to whoever is using this component
                    // setText(val);

                    combobox.closeDropdown();
                }}
            >
                <Combobox.Target withAriaAttributes={false}>
                    <TextInput
                        // disabled={disabled}
                        {...textProps}
                        ref={inputRef}
                        value={selectedItem?.value || text}
                        onChange={(event) => {

                            textChanged.current = true;
                            setCompletedText(null);
                            setText(event.currentTarget.value);

                            //dropdown might be closed, so let's make sure it's open again when someone is typing.

                        }}
                        onKeyDown={(e) => {
                            // console.log("ON KEY DOWN!!!", e.key);
                            //was it a backspace?
                            if (e.key === "Backspace") {
                                console.log("backspace");
                                setSelectedItem(null);
                                console.log("Text is", text);
                            }
                        }}
                        onClick={() => combobox.openDropdown()}
                        onFocus={() => combobox.openDropdown()}
                        onBlur={() => combobox.closeDropdown()}

                    />


                </Combobox.Target>


                <Combobox.Dropdown>

                    <Combobox.Options mah={300} style={{ overflowY: 'auto' }}>

                        {
                            isGrouped ?
                                Object.keys(groupedOptions).map((keyValue: string) => <Combobox.Group label={keyValue}>

                                    {(groupedOptions[keyValue as any]
                                    ).map((item: any, i: Key) => (
                                        <Combobox.Option value={item.value} key={i}>
                                            <Text>{item.label}</Text>
                                        </Combobox.Option>
                                    ))}

                                </Combobox.Group>
                                )
                                :

                                options.length > 0 ? options.map((item, i) => (
                                    <Combobox.Option value={item.value} key={i}>
                                        <Text>{item.label}</Text>
                                    </Combobox.Option>
                                )) : <Combobox.Empty>Nothing found</Combobox.Empty>

                        }
                    </Combobox.Options>

                </Combobox.Dropdown>

            </Combobox>

        );
    });

