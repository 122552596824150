import { useRef, useState } from "react";
import { RMUser } from "../../types";
import { Button, Group, Modal, Title, } from "@mantine/core";
import { BsPlusCircle, } from "react-icons/bs";
import AddRmForm from "./AddRmForm";
import UpdateRMPasswordModal from "./UpdateRMPasswordModal";
import RmsInstantSearch, { RefreshButton, RefreshButtonRef } from "../../components/rms_instant_search";

const RMsListPage = () => {

    const [addRmModal, setAddRmModal] = useState<boolean>(false);
    const [updateRmModal, setUpdateRmModal] = useState<boolean>(false);
    const [rmUser, setRmUser] = useState<RMUser | null>();
    const refreshRef = useRef<RefreshButtonRef | null>(null);


    return (
        <div>
            <Group justify="space-between">
                <Title>KFH RMs</Title>

            </Group>
            <Modal
                title=
                {<Title>
                    {rmUser ? "Update" : "Add"} KFH RM
                </Title>}
                opened={addRmModal}
                onClose={() => {
                    setAddRmModal(false)

                    setRmUser(null)
                }

                }
            >
                <AddRmForm rmUser={rmUser}
                    handleSubmit={() => {
                        setAddRmModal(false)
                        setRmUser(null)
                        refreshRef.current?.refresh();
                    }
                    }

                />

            </Modal>
            {rmUser &&
                <>
                    <UpdateRMPasswordModal
                        open={updateRmModal}
                        user={rmUser}
                        onClose={() => {
                            setUpdateRmModal(false)
                            setRmUser(null)
                        }}
                        handleSubmit={() => {
                            refreshRef.current?.refresh();
                        }}
                    />

                </>
            }

            <RmsInstantSearch
                setUpdateRmModal={setUpdateRmModal}
                setAddRmModal={setAddRmModal}
                setRmUser={setRmUser}
                indexName={process.env.REACT_APP_TYPESENSE_RMS_COLLECTION as string}
                topElements={<Button
                    leftSection={<BsPlusCircle />}
                    onClick={() => setAddRmModal(true)}
                >
                    Add RM
                </Button>}
                additionalChildren={<RefreshButton />}
            />

        </div>
    )
}

export default RMsListPage