import { Checkbox, Grid, NumberInput, } from '@mantine/core'



import { useContext, useEffect, useMemo, useState } from 'react';
import { ConfigContext } from '../../contexts';
import BigNumber from 'bignumber.js';
import { CustodianAccountWithBalance, FirebaseTransaction } from '../../types';
import { UseFormReturnType } from '@mantine/form';
import { AutocompleteComponent } from '../../components/autocomplete_component';


interface CashFormProps {
    form: UseFormReturnType<FirebaseTransaction>
    disabled: boolean
}



const CashForm = ({ form, disabled, ...props }: CashFormProps) => {

    const configContext = useContext(ConfigContext);

    // let [selectedCurrency, setSelectedCurrency] = useState({} as any);

    let [currencies, setCurrencies] = useState({} as any);



    /*
        if (form.values.transactionType === "cash_in") {
            */

    useMemo(() => {

        console.log("Doing currencies again!");

        let c = Object.keys(configContext!.exchange_rates?.rates || {}).map((currencyKey: any) => {

            let rate = configContext!.exchange_rates?.rates[currencyKey];

            let data = {} as any;

            data["text_l"] = currencyKey;
            data["text_r"] = ""
            data["value"] = currencyKey;
            data["key"] = currencyKey;
            data["id"] = currencyKey;
            data["rate"] = rate;


            return data;
        })

        c.push({
            text_l: "KWD",
            text_r: "",
            value: "KWD",
            key: "KWD",
            id: "KWD",
            rate: "1"
        })

        //sort currencies
        c.sort((a: any, b: any) => {
            if (a.id < b.id) {
                return -1;
            }
            if (a.id > b.id) {
                return 1;
            }
            return 0;
        })

        //multiply rate by 1000 because of the 3 decimal precision
        c = c.map((currency: any) => {
            currency.rate = BigNumber(currency.rate).toString();
            return currency;
        });

        setCurrencies(c);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    /*
        }
    
        else {
            currencies = form.values.position?.filter((position: any) => position._INVESTMENT_INVESTMENTTYPE_CODE_ === "CASH").map((cash: any) => {
    
    
                let rate = configContext!.exchange_rates?.rates[cash._DENOMINATION_CODE_] || "1";
                rate = BigNumber(rate).dividedBy(1000).toString();
    
                let data = {} as any;
    
                data["text_l"] = cash._DENOMINATION_CODE_;
                data["text_r"] = `Balance: ${cash.COSTLOCALEND}`
                data["value"] = cash._DENOMINATION_CODE_;
                data["key"] = cash._DENOMINATION_CODE_;
                data["id"] = cash._DENOMINATION_CODE_;
                data["rate"] = rate;
    
    
                return data;
                // return {
                //     currency: cash.CURRENCYCODE,
                //     rate: rate,
                //     balance: cash.CASHBALANCE
                // }
            }) || [];
        }
        */

    useEffect(() => {
        if (disabled) return;

        if (!form.values.amount || !form.values.rate) {
            console.warn("amount or rate not set");
            return;

        }

        let convertedAmount = BigNumber(form.values.rate).multipliedBy(form.values.amount).toNumber();
        form.setFieldValue("convertedAmount", convertedAmount);

        // form.setValues((prev) => ({ ...prev, ...{ "convertedAmount": convertedAmount } }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form.values.amount, form.values.rate]);

    useEffect(() => {
        if (disabled) return;
        if (form.values.transactionType === "cash_out") {

            if (!form.values.custodianAccount) return;

            const custodianAccount = form.values.custodianAccount as CustodianAccountWithBalance;

            if (custodianAccount.unitCost) {
                console.log("The chosen custodian account!", form.values.custodianAccount, custodianAccount.unitCost);
                form.setFieldValue("rate", custodianAccount.unitCost);
            }


        }
        else {

            //find the currency rate
            console.log("Looking for ", form.values.currency);
            if (form.values.currency === "KWD") {
                form.setFieldValue("rate", 1);
                return;
            }

            // console.log(currencies);

            let item = currencies.find((currency: any) => currency.value === form.values.currency);
            if (item) {
                console.log("Found ", item);
                form.setFieldValue("rate", parseFloat(item.rate));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currencies, form.values.currency, form.values.transactionType, form.values.custodianAccount?.id]);



    return (

        <>


            <Grid.Col span={{ lg: 2, xs: 2 }} >
                <NumberInput
                    decimalScale={3}
                    disabled={disabled}
                    // withAsterisk
                    // required
                    label="Amount "
                    min={0}
                    placeholder="amount"

                    {...form.getInputProps("amount")}

                    // parser={(value: string) => value.replace(new RegExp(`(,*)`, "g"), '')}

                    // formatter={(value: number | string) =>
                    //     !Number.isNaN(parseFloat(value))
                    //         ? `${value}`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
                    //         : ''
                    // }

                    onChange={(value) => {
                        form.setFieldValue("amount", Number(value))
                        //change converted amount

                    }}
                />
            </Grid.Col>
            <Grid.Col span={{ lg: 4, xs: 4 }} >

                <AutocompleteComponent
                    // comboboxProps={{ withinPortal: true }}
                    // required
                    // disabled={disabled}
                    // value={disabled ? form.currency : form.currencyValue}
                    // limit={100}
                    defaultItem={form.values.currency}
                    textProps={{
                        disabled: true,
                        defaultValue: form.values.currency,
                        placeholder: "Currency",
                        label: "Currency"

                    }}
                    text_r=""
                    value=""
                    // {...form.getInputProps("currency")}

                    // itemComponent={AutoCompleteItem}

                    handleSubmit={(item) => {
                        // const item = currencies.find((currency: any) => currency.value == itemString)
                        // setSelectedCurrency(item);
                        //set the rate
                        form.setFieldValue("currency", item);

                        // let valuesToUpdate: any = {
                        //   currencyFromValue: item.value,
                        // }
                        // if (item.value !== "KWD") {
                        //   valuesToUpdate = { ...valuesToUpdate, "currencyToValue": "KWD", "customRate": formData.rate }
                        // }
                        // setFormData({ ...formData, ...valuesToUpdate })
                        // form?.setValues((prev: any) => ({ ...prev, ...valuesToUpdate }))
                        // // form?.setFieldValue("customRate", formData.rate)
                        // console.log(formData);
                    }}

                    // label= {form.values.action === "Deposit" ? "Currency" : "Cash Available"}
                    data={currencies}

                />

            </Grid.Col>
            <Grid.Col span={{ lg: 2, xs: 2 }} >

                {form.values.currency !== "KWD" &&
                    <NumberInput
                        decimalScale={4}
                        // withAsterisk
                        // required
                        label="Rate"
                        min={0}
                        placeholder="Rate"
                        disabled={form.values.transactionType === "cash_out" ? true : disabled}

                        {...form.getInputProps("rate")}
                        onChange={(value) => {
                            //change converted amount
                            // console.log("rate changed", e.target.value);


                            form.setFieldValue("rate", Number(value));
                            // console.log("rate changed", selectedCurrency.rate, form.values.amount, convertedAmount);


                        }}
                    />
                }
            </Grid.Col>
            <Grid.Col span={{ lg: 2, xs: 2 }} >
                {form.values.currency !== "KWD" &&


                    <NumberInput
                        decimalScale={4}
                        // withAsterisk
                        // required
                        label="In KWD"
                        disabled={disabled || form.values.transactionType === "cash_out"}

                        min={0}
                        placeholder="converted amount"

                        {...form.getInputProps("convertedAmount")}
                        onChange={(convertedAmount) => {

                            if (!form.values.amount) {
                                console.warn("No amount available")
                                return;
                            }
                            //change rate
                            // console.log("converted amount changed", e.target.value);
                            form.setFieldValue("convertedAmount", Number(convertedAmount));

                            let rate = BigNumber(convertedAmount).dividedBy(form.values.amount).toNumber();
                            form.setFieldValue("rate", rate);


                        }}
                    />
                }
            </Grid.Col>
            {/* {BigNumber(selectedCurrency.rate).multipliedBy(form.values.amount).dividedBy(1000).toFixed(3).toString()}</Text>} */}


            {/* <Grid.Col span={{lg: 4 ,xs:4 }} >

                <Radio.Group
                    label="Action"
                    description="action of cash flow update"

                    {...form.getInputProps('action')}
                >
                    <Radio mt="xs" value="Deposit" label="Deposit" />
                    <Radio mt="xs" value="Withdraw" label="Withdraw" />

                </Radio.Group>


            </Grid.Col> */}
            <Grid.Col span={{ lg: 2, xs: 2 }} >
                <Checkbox
                    disabled={disabled}
                    style={{ marginTop: 25 }}
                    label="Margin"
                    {...form.getInputProps('margin')}

                // checked={usePACI}
                // onChange={(e) => setUsePACI(e.target.checked)}
                />
            </Grid.Col>
            {/* <Grid.Col lg={12} xs={12} >
                <CashAvailable form={form} />
            </Grid.Col> */}

        </>

    )
}

export default CashForm