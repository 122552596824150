import { Card, Container, Grid, Stack, Title } from '@mantine/core'
import React from 'react'

const FormCardWrapper = ({ children, title, justify = "center", gridProps }: any) => {
  return (
    <Stack >
      <Title>{title || ""}</Title>

      <Card mt="xs" shadow="sm" p="xl" radius="md" withBorder >



        <Grid ta="center" p={"xl"} justify={justify} {...gridProps} >
          {children}
        </Grid>

      </Card>
    </Stack>
  )
}

export default FormCardWrapper